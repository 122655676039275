import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { Divider, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Card from '../../assets/components/Card/Card';
import { pushToError, settingsApi } from '.../utils/apiHelper';

const styles = theme => ({
    cardStyle: {
        maxWidth: '850px',
        margin: 'auto'
    },
    rightAlignText: {
        marginLeft: '25px',
        marginRight: '25px',
        textAlign: 'right'
    },
    leftAlignText: {
        marginLeft: '25px',
        marginRight: '25px',
        textAlign: 'left'
    },
    genInfoFlexContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    titleRow: {
        textAlign: 'center',
        backgroundColor: 'rgba(0,0,0,.7)',
        color: 'rgba(255, 255, 255, 1)'
    }
});

function AboutPage(props) {
    const { classes } = props;
    const [ aboutInfo, setAboutInfo] = React.useState({});
    let patientId = props.patientInfo === null ? undefined : props.patientInfo.patientId;

    React.useEffect(() => {
        settingsApi.getAboutInfo().then((newAboutInfo) => {
            setAboutInfo(newAboutInfo);
        }).catch(error => {
            pushToError(error, patientId);
        });
    }, [ patientId ]);

    let renderKeyValueList = (list, keyPropName, valuePropName) => {
        if (Array.isArray(list) && list.length > 0) {
            let greyRow = true;
            return list.map((item) => {
                greyRow = !greyRow;
                return renderKeyValueItem(item, greyRow, keyPropName, valuePropName);
            });
        } else {
            return (
                <CircularProgress style={{margin: 'auto'}} size={30} thickness={7} />
            );
        }
    };

    let renderKeyValueItem = (item, greyRow, keyPropName, valuePropName) => {
        if (item && item[valuePropName] && item[keyPropName]) {
            return (
                <div className={classes.genInfoFlexContainer} style={greyRow ? { backgroundColor: 'rgba(0,0,0,.1)' } : undefined}>
                    <Typography variant="body1" className={classes.leftAlignText}>
                        {item[keyPropName]}
                    </Typography>
                    <Typography variant="body1" className={classes.leftAlignText}>
                        {item[valuePropName]}
                    </Typography>
                </div>
            );
        }
    };

    let environmentInfoList = [];
    if (aboutInfo?.tenant){
        environmentInfoList.push(
            {
                name: "Login Tenant:",
                value: aboutInfo?.tenant
            }
        );
    }
    if (aboutInfo?.databaseServerName){
        environmentInfoList.push(
            {
                name: "Database Server:",
                value: aboutInfo?.databaseServerName
            }
        );
    }
    if (aboutInfo?.databaseName){
        environmentInfoList.push(
            {
                name: "Database Name:",
                value: aboutInfo?.databaseName
            }
        );
    }
    if (aboutInfo?.apolloTree && !aboutInfo?.isAzureDeployment){
        environmentInfoList.push(
            {
                name: "Apollo Tree:",
                value: aboutInfo?.apolloTree
            }
        );
    }

    return (
        <Card className={classes.cardStyle} style={{maxHeight: '100%', overflow:'auto'}}>
            <Typography variant="h4" className={classes.titleRow}>
                Embedded Analytics
            </Typography>
            <Divider />
            <div className={classes.genInfoFlexContainer}>
                <div>
                    <Typography variant="body1" className={classes.leftAlignText}>
                        Version: 3.5.1
                    </Typography>
                    <Typography variant="body1" className={classes.leftAlignText}>
                        © 2024 LUMEDX Corporation
                    </Typography>
                    <Typography variant="body1" className={classes.leftAlignText}>
                        All Rights Reserved
                    </Typography>
                </div>
                <div>
                    <Typography variant="body1" className={classes.rightAlignText}>
                        LUMEDX Corporation, an Intelerad Company
                    </Typography>
                    <Typography variant="body1" className={classes.rightAlignText}>
                        305 Church at N Hills St
                    </Typography>
                    <Typography variant="body1" className={classes.rightAlignText}>
                        6th floor, Suite 2060
                    </Typography>
                    <Typography variant="body1" className={classes.rightAlignText}>
                        Raleigh, North Carolina 27609 USA.
                    </Typography>
                </div>
            </div>
            <br />
            <Typography variant="body1" className={classes.titleRow} >
                Environment Information:
            </Typography>
            {renderKeyValueList(environmentInfoList, "name", "value")}
            <Divider />
            <br />
            <Typography variant="body1" className={classes.titleRow}>
                Installed Modules:
            </Typography>
            {renderKeyValueList(aboutInfo.modules, "name", "version")}
        </Card>
    );
} 

export default withStyles(styles)(withRouter(connect((state) => ({ patientInfo: state.demographics }))(AboutPage)));