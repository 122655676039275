import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import withStyles from "@material-ui/core/styles/withStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import headerStyle from "../../jss/material-dashboard-pro-react/components/headerStyle";
import Button from "../CustomButtons/Button";

const styles = theme => ({
    ...headerStyle(theme),
    drawerFull: {
        width: 'calc(100% - 260px)',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    drawerMini: {
        width: 'calc(100% - 80px)',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    toggleButton: {
        minWidth: '28px',
        width: '28px',
        padding: '0px !important'        
    }
});

function Header({ ...props }) {
    function makeBrand() {
        var name;
        props.routes.map((prop, key) => {
            // Doesn't work with the view promises, probably need to make this a class with state to work with promises
            // if (typeof prop.views !== 'undefined') {
            //     prop.views.map((prop, key) => {
            //         if (props.location.pathname.indexOf(prop.path) > 0) {
            //             name = prop.name;
            //         }
            //         return null;
            //     });
            // }
            let propPath = prop.path;

            if (props.patientInfo !== null) {
                propPath = propPath.replace(':mrn', props.patientInfo.patientId);
            }

            if (props.location.pathname.indexOf(propPath) >= 0) {
                name = prop.name;
            }
            return null;
        });
        if (name==='FORMS'){
            return props.formName;
        }
        else{
            return name;
        }
    }
    const { classes, color, rtlActive, patientInfo } = props;
    const appBarClasses = cx({
        [" " + classes[color]]: color
    });
    // const sidebarMinimize =
    //     classes.sidebarMinimize +
    //     " " +
    //     cx({
    //         [classes.sidebarMinimizeRTL]: rtlActive
    //     });
    // const hasPatientInfo = typeof patientInfo !== 'undefined' && patientInfo !== null;
    return (
        <AppBar className={(props.miniActive ? classes.drawerMini : classes.drawerFull) + " " + classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                {/* Below code has been commented to remove the sidebarmini toggle button for MCP 3.5.1 release. 
                 <Hidden smDown>
                    <div className={sidebarMinimize}>
                        {props.miniActive ? (
                            <Button
                                className={classes.toggleButton}
                                justIcon
                                round
                                color="white"
                                onClick={props.sidebarMinimize}
                            >
                                <ViewList className={classes.sidebarMiniIcon} />
                            </Button>
                        ) : (
                                <Button
                                    className={classes.toggleButton}
                                    justIcon
                                    round
                                    color="white"
                                    onClick={props.sidebarMinimize}
                                >
                                    <MoreVert className={classes.sidebarMiniIcon} />
                                </Button>
                            )}
                    </div>
                </Hidden>*/}
                <div className={classes.flex}  style={{ height: '1px' }}>
                    {/* Here we create navbar brand, based on route name */}

                    <div className={classes.pageTitle}>
                        {makeBrand()}
                    </div>
                </div>  
                <Hidden mdUp>
                    <Button
                        className={classes.appResponsive}
                        color="transparent"
                        justIcon
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >

                        <Menu />
                    </Button>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    rtlActive: PropTypes.bool
};

export default withStyles(styles)(withRouter(connect((state) => ({ patientInfo: state.demographics }), null)(Header)));
