import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import AccessibilityNew from "@material-ui/icons/Accessibility";
import AssignmentIcon from '@material-ui/icons/Assignment';
import BubbleChart from "@material-ui/icons/BubbleChart";
import CompareArrows from "@material-ui/icons/CompareArrows";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ErrorIcon from '@material-ui/icons/Error';
import Favorite from "@material-ui/icons/Favorite";
import Fingerprint from "@material-ui/icons/Fingerprint";
import InfoIcon from '@material-ui/icons/Info';
import InsertChart from "@material-ui/icons/InsertChart";
import SecurityIcon from '@material-ui/icons/Security';
import cx from "classnames";
import React from 'react';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import Header from ".../assets/components/Header/Header";
import Sidebar from ".../assets/components/Sidebar/Sidebar";
import logo from ".../assets/img/lumedx.ico";
import appStyle from ".../assets/jss/material-dashboard-pro-react/layouts/dashboardStyle";
import ChestPainContainer from '.../components/features/risk/chestpain/ChestPainContainer';
import RiskForm from '.../components/features/risk/RiskForm';
import RiskFormConfigurations from '.../components/features/risk/RiskFormConfigurations';
import ResetAppContextContainer from '.../components/settings/ResetAppContextContainer';
import { CheckAuthorization } from '.../utils/AuthorizationFilter';
import DevInfo from '.../utils/DevInfo';
import userManager from '.../utils/userManager';
import Dashboard from '../dashboard/Dashboard';
import MappingManagerContainer from '../features/emrMapping/MappingManagerContainer';
import FormEditor from '../features/forms/formEditor/FormEditorContainer';
import FormsRoutesContainer from '../features/forms/FormsRoutesContainer';
import GetFormViews from '../features/forms/GetFormViews';
import GetHvaViews from '../features/hva/GetHvaViews';
import HvaRoutes from '../features/hva/HvaRoutes';
import IPCCCContainer from "../features/ipccc/IPCCCContainer";
import SabermedicsRoutes from '../features/sabermedics/SabermedicsRoutes';
import StaffContainer from '../features/staff/StaffContainer';
import SelectionSetContainer from '../features/selectionSet/SelectionSetContainer';
import LookupTableContainer from '../features/lookupTable/LookupTableContainer';
import TrendsContainer from "../features/trends/TrendsContainer";
import WebpacsRoutes from '../features/webpacs/WebpacsRoutes';
import PatientEventsContainer from '../patientevents/PatientEventsContainer';
import GetWorklistViews from '../patientSearch/GetWorklistViews';
import PatientWorklistContainer from '../patientSearch/PatientWorklistContainer';
import WorklistEventNavigator from '../patientSearch/WorklistEventNavigator';
import WorklistRoutesContainer from '../patientSearch/WorklistRoutesContainer';
import AboutPage from './AboutPage';
import ErrorPage from './ErrorPage';
import NotFound from './NotFound';
import Unauthorized from './Unauthorized';
import ViewRouteLoader from './ViewRouteLoader';

const styles = theme => ({
    ...appStyle(theme),
    topBorder: {
        backgroundImage: `linear-gradient(to right, ${theme.palette.lxBlue.main}, ${theme.palette.lxGreen.main})`,
        height: `${theme.topBarOffset}px`,
        width: '100%',
        position: 'fixed',
        zIndex: '9999'
    },
    '@global': {
        body: {
            padding: '0px',
            margin: '0px',
            textAlign: 'center',
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily
        }
    },
    scrollContainer: {
        height: `calc(100% - ${theme.topBarOffset}px)`,
        '& .ps__rail-y': {
            zIndex: 1500
        }
    },
    scrollDiv: {
        height: 'inherit',
        position: 'relative',
        top: `${theme.topBarOffset}px`,
        overflowY: 'hidden'
    }
});



class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.managedWindow = sessionStorage.getItem('managedWindow');
        this.scrollableDivRef = null;
        this.state = {
            routes: [],
            adminToolsInfo: null,
            isDrawerOpen: false,
            isLoading: true,
            mobileOpen: false,
            miniActive: false
        };
    }

    componentDidMount() {
        let mrn = this.props.patientInfo !== null ? this.props.patientInfo.patientId : undefined;

        document.body.style.overflowY='hidden';
        this.getUpdatedRoutes(mrn, this.props.recordInfo,this.props.patientInfo).then(({ routes, adminToolsInfo }) => {
            this.setState({
                routes,
                adminToolsInfo,
                isLoading: false
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let newMrn = this.props.patientInfo !== null ? this.props.patientInfo.patientId : undefined;
        let oldMrn = prevProps.patientInfo !== null ? prevProps.patientInfo.patientId : undefined;
        let newRecordTable = this.props.recordInfo !== null ? this.props.recordInfo.recordTable : undefined;
        let oldRecordTable = prevProps.recordInfo !== null ? prevProps.recordInfo.recordTable : undefined;
        let newRecordId = this.props.recordInfo !== null ? this.props.recordInfo.recordId : undefined;
        let oldRecordId = prevProps.recordInfo !== null ? prevProps.recordInfo.recordId : undefined;

        if (newMrn !== oldMrn || newRecordTable !== oldRecordTable || newRecordId !== oldRecordId) {
            this.getUpdatedRoutes(newMrn, this.props.recordInfo,this.props.patientInfo).then(({ routes, adminToolsInfo }) => {
                this.setState({
                    routes,
                    adminToolsInfo
                });
            });
        }
    }

    getUpdatedRoutes = (mrn, recordInfo, patientInfo) => new Promise((resolve, reject) => {
        const hasPatientContext = typeof mrn !== 'undefined';
        const routePrepender = hasPatientContext ? `/patient/:mrn` : '';

        let routes = [];
        let adminToolsInfo = null;

        Promise.all([
            CheckAuthorization('Sabermedics:User'),
            CheckAuthorization('HVA:User'),
            CheckAuthorization('WebPacs:User'),
            CheckAuthorization('IPCCC:User'),
            CheckAuthorization('STSRisk:User'),
            CheckAuthorization('TimiStemi:User'),
            CheckAuthorization('TimiUaNstemi:User'),
            CheckAuthorization('ChadsVasc:User'),
            CheckAuthorization('EdChestPainEval:User'),
            CheckAuthorization('Trends:User'),
            CheckAuthorization('CommonFeatures:User'),
            CheckAuthorization('WebForms:User'),
            CheckAuthorization('WebForms:Administrator'),
            CheckAuthorization('EmrDataIntegration:Administrator'),
            CheckAuthorization('CommonFeatures:Administrator'),
            userManager.getUser()
        ]).then(([
            sabermedics,
            hva,
            webpacs,
            ipccc,
            stsrisk,
            timiStemi,
            timiUanStemi,
            chadsVasc,
            edChestPain,
            trends,
            commonFeatures,
            forms,
            formsAdmin,
            emrAdmin,
            commonFeaturesAdmin,
            user
        ]) => {
            let enabledFeatures = [];

            if (commonFeatures) {
                if (hasPatientContext) {
                    if (webpacs) {
                        routes.push({
                            path: `${routePrepender}/webpacs`,
                            name: "WEBPACS",
                            icon: <Favorite />,
                            link: true,
                            component: WebpacsRoutes
                        });
                        enabledFeatures.push('webpacs');
                    }

                    if (ipccc) {
                        routes.push({
                            path: `${routePrepender}/ipccc`,
                            name: "IPCCC",
                            icon: <Fingerprint />,
                            link: true,
                            component: IPCCCContainer
                        });
                        enabledFeatures.push('ipccc');
                    }

                    if (timiStemi || timiUanStemi || chadsVasc) {
                        let riskViews = [];

                        if (timiStemi) {
                            riskViews.push({
                                path: `${routePrepender}/risk/timistemi`,
                                name: "Timi Stemi",
                                mini: "TS",
                                link: true,
                                component: () => <RiskForm configuration={RiskFormConfigurations.TimiStemiConfiguration} />
                            });
                        }

                        if (timiUanStemi) {
                            riskViews.push({
                                path: `${routePrepender}/risk/timiuanstemi`,
                                name: "Timi UA/NStemi",
                                mini: "TUNS",
                                link: true,
                                component: () => <RiskForm configuration={RiskFormConfigurations.TimiUaNstemiConfiguration} />
                            });
                        }

                        if (chadsVasc) {
                            riskViews.push({
                                path: `${routePrepender}/risk/chadsvasc`,
                                name: "Chads Vasc",
                                mini: "CV",
                                link: true,
                                component: () => <RiskForm configuration={RiskFormConfigurations.ChadsvascConfiguration} />
                            });
                        }

                        routes.push({
                            path: `${routePrepender}/risk`,
                            name: "RISK",
                            icon: <AccessibilityNew />,
                            link: false,
                            component: () => <ViewRouteLoader views={riskViews} basePath={`${routePrepender}/risk`} />,
                            views: riskViews
                        });
                        enabledFeatures.push('stsrisk');
                    }

                    if (edChestPain) {
                        routes.push({
                            path: `${routePrepender}/edchestpaineval`,
                            name: "Chest Pain Evaluation",
                            mini: "CPE",
                            link: true,
                            component: () => <ChestPainContainer />
                        });
                        enabledFeatures.push('edchestpaineval');
                    }

                    if (trends) {
                        routes.push({
                            path: `${routePrepender}/trends`,
                            name: "TRENDS",
                            icon: <BubbleChart />,
                            link: true,
                            component: TrendsContainer
                        });
                        enabledFeatures.push('trends');
                    }

                } else {
                    routes.push({
                        path: "/",
                        exact: true,
                        name: "SEARCH",
                        icon: <DashboardIcon />,
                        link: true,
                        component: PatientWorklistContainer
                    });

                    routes.push({
                        path: `${routePrepender}/worklist`,
                        exact: true,
                        link: false,
                        name: "WORKLISTS",
                        icon: <DashboardIcon />,
                        views: GetWorklistViews()
                    });
                }

                if (sabermedics) {
                    routes.push({
                        path: `${routePrepender}/sabermedics`,
                        name: "SABERMEDICS",
                        icon: <CompareArrows />,
                        link: true,
                        component: SabermedicsRoutes
                    });
                    enabledFeatures.push('sabermedics');
                }

                if (hva && typeof user.profile.azureupn !== 'undefined') {
                    routes.push({
                        path: `${routePrepender}/hva`,
                        name: "HVA",
                        icon: <InsertChart />,
                        link: false,
                        component: HvaRoutes,
                        views: GetHvaViews(mrn)
                    });
                    enabledFeatures.push('hva');
                }

                if (hasPatientContext && forms) {
                    if (recordInfo) {
                        routes.unshift({
                            path: `${routePrepender}/forms`,
                            name: "FORMS",
                            icon: <AssignmentIcon />,
                            exact: true,
                            views: GetFormViews(hasPatientContext, recordInfo.recordTable, recordInfo.recordId)
                        });
                        enabledFeatures.push('forms');
                    }
                    routes.unshift({
                        path: `${routePrepender}/patientevents`,
                        name: "PATIENT EVENTS",
                        icon: <DashboardIcon/>,
                        link: true,
                        exact: true,
                        component: () => <PatientEventsContainer patientInfo={patientInfo}/>
                    });
                }

                routes.push({
                    path: `${routePrepender}/aboutCopilot`,
                    name: "ABOUT",
                    icon: <InfoIcon />,
                    link: true,
                    exact: true,
                    component: () => <AboutPage />
                });

                if (formsAdmin || emrAdmin || commonFeaturesAdmin) {
                    let enabledAdminTools = [];
                    adminToolsInfo = {
                        views: [],
                        route: null
                    };

                    if (formsAdmin) {
                        adminToolsInfo.views.push({
                            path: `${routePrepender}/adminTools/formeditor`,
                            name: 'FORM EDITOR',
                            component: FormEditor
                        });
                        enabledAdminTools.push('formsEditor');
                    }

                    if (emrAdmin) {
                        adminToolsInfo.views.push({
                            path: `${routePrepender}/adminTools/emrmappingmanager`,
                            name: 'EMR MAPPING MANAGER',
                            component: MappingManagerContainer
                        });
                        enabledAdminTools.push('emrMappingManager');
                    }

                    if (commonFeaturesAdmin) {
                        adminToolsInfo.views.push({
                            path: `${routePrepender}/adminTools/debug`,
                            name: 'DEBUG',
                            component: DevInfo
                        });
                        enabledAdminTools.push('debug');
                    }

                    adminToolsInfo.views.push({
                        path: `${routePrepender}/adminTools/staffManager`,
                        name: 'STAFF MANAGER',
                        component: StaffContainer
                    });
                    enabledAdminTools.push('staffManager');

                    adminToolsInfo.views.push({
                        path: `${routePrepender}/adminTools/selectionSetManager`,
                        name: 'SELECTION SET MANAGER',
                        component: SelectionSetContainer
                    });
                    enabledAdminTools.push('selectionSetManager');

                    adminToolsInfo.views.push({
                        path: `${routePrepender}/adminTools/lookupTableManager`,
                        name: 'LOOKUP TABLE MANAGER',
                        component: LookupTableContainer
                    });
                    enabledAdminTools.push('lookupTableManager');

                    adminToolsInfo.views.push({
                        path: `${routePrepender}/adminTools/resetAppContext`,
                        name: 'RESET APP CONTEXT',
                        component: ResetAppContextContainer
                    });
                    enabledAdminTools.push('resetAppContext');

                    adminToolsInfo.route = {
                        path: `${routePrepender}/adminTools`,
                        name: 'ADMIN TOOLS',
                        icon: <SecurityIcon />,
                        link: true,
                        component: () => <Dashboard modules={enabledAdminTools} />
                    };
                }
            } else {
                routes.push({
                    path: `/`,
                    name: 'UNAUTHORIZED',
                    icon: <ErrorIcon />,
                    link: false,
                    component: () => <Unauthorized features={['CommonFeatures:User']} />
                });
            }

            resolve({ routes, adminToolsInfo });
        });
    })

    toggleDrawer = () => {
        this.setState({
            isDrawerOpen: !this.state.isDrawerOpen
        });
    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    }

    sidebarMinimize = () => {
        this.setState({ miniActive: !this.state.miniActive });
    }

    triggerWindowResize = () => {
        let event;
        if (typeof Event === 'function') {
            event = new Event('resize');
        } else {
            event = document.createEvent('Event');
            event.initEvent('resize', true, true);
        }
        window.dispatchEvent(event);
    }


    triggerWindowScroll = () => {
        let event;
        if (typeof Event === 'function') {
            event = new Event('scroll');
        } else {
            event = document.createEvent('Event');
            event.initEvent('scroll', true, true);
        }
        window.dispatchEvent(event);
    }

    scrollContentToCenter = (elementRef) => {
        let halfViewportHeight = window.innerHeight / 2;
        if (this.scrollableDivRef && elementRef) {
            let domRect = elementRef.getBoundingClientRect();
            //If the element that is being scrolled into center is below halfway down the page, don't shift the scrollbar up a half page. This is because it must be towards the bottom of the content.
            if (domRect.top <= halfViewportHeight) {
                this.scrollableDivRef.scrollTop = this.scrollableDivRef.scrollTop - halfViewportHeight;
            }
        }
    }

    render() {
        const { classes, patientInfo } = this.props;
        const { isLoading, routes, adminToolsInfo } = this.state;
        const mainPanel = cx({
            [classes.scrollContainer]: true,
            [classes.mainPanel]: true,
            [classes.mainPanelSidebarMini]: this.state.miniActive
        });

        return (
            <div className={classes.wrapper}>
                <div className={classes.topBorder} />
                <div className={mainPanel} ref="mainPanel">
                    <Header
                        //sidebarMinimize={this.sidebarMinimize}
                        //miniActive={this.state.miniActive}
                        routes={routes.concat(adminToolsInfo !== null ? adminToolsInfo.route : [])}
                        handleDrawerToggle={this.handleDrawerToggle}
                        formName={this.props.location.pathname.split('/')[6] !== null ? this.props.location.pathname.split('/')[6] : null}
                        patientInfo={patientInfo}
                    />
                    <Sidebar
                        routes={routes}
                        adminToolsInfo={adminToolsInfo}
                        logoText={"Medical Copilot"}
                        logo={logo}
                        handleDrawerToggle={this.handleDrawerToggle}
                        open={this.state.mobileOpen}
                        color="blue"
                        bgColor="white"
                        miniActive={this.state.miniActive}
                        isManagedWindow={this.managedWindow}
                    />
                    <div className={classes.scrollDiv} ref={(ref) => { this.scrollableDivRef = ref; }} >
                        <div className={classes.content}>
                            {isLoading ?
                                <CircularProgress size={60} thickness={7} />
                                :
                                <Switch>
                                    {patientInfo !== null && (routes.length !== 1 || routes[0].path !== '/') ?
                                        <Route exact path="/patient/:mrn" component={() =>
                                            <Redirect
                                                to={`${routes.length === 1 ?
                                                    routes[0].path.replace(':mrn', patientInfo.patientId)
                                                    :
                                                    `${this.props.location.pathname}/patientevents`}`
                                                }
                                            />
                                        }
                                        />
                                        :
                                        null
                                    }
                                    {routes.map(route =>
                                        <Route exact={typeof route.exact !== 'undefined' && route.exact} path={route.path} component={route.component} key={route.name} />
                                    )}

                                    <Route exact path="/patient/:mrn/forms/:recordTable/:recordId/:formName" component={() => <FormsRoutesContainer triggerScroll={this.scrollContentToCenter} />} />
                                    <Route exact path="/worklist/eventNavigator/:tableName/:keyValue" component={() => <WorklistEventNavigator />} />
                                    <Route exact path="/worklist/:description/:filterNamespace/:filterName" component={() => <WorklistRoutesContainer />} />

                                    <Route path="/error" component={ErrorPage} />
                                    <Route path="/patient/:mrn/error" component={ErrorPage} />
                                    {adminToolsInfo === null ?
                                        null
                                        :
                                        <Switch>
                                            <Route exact path={adminToolsInfo.route.path} component={adminToolsInfo.route.component} />
                                            {adminToolsInfo.views.map(view =>
                                                <Route exact={typeof view.exact !== 'undefined' && view.exact} path={view.path} component={view.component} key={view.name} />
                                            )}
                                            <Route path={`${adminToolsInfo.route.path}/`} component={NotFound} />
                                        </Switch>
                                    }
                                    <Route component={NotFound} />
                                </Switch>
                            }
                            <ReactResizeDetector
                                handleWidth
                                onResize={this.triggerWindowResize}
                                refreshMode='throttle'
                                refreshRate={300}
                                refreshOptions={{ leading: true }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(
    withStyles(styles),
    connect((state) => ({ patientInfo: state.demographics, recordInfo: state.recordInfo })),
    withRouter
)(MainPage);
