import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import { pushToError, reportApi } from '.../utils/apiHelper';
import userManager from ".../utils/userManager";
import ReportSelector from './ReportSelector';
import ReportTemplateSelector from './ReportTemplateSelector';
import ReportViewer from './ReportViewer';

const styles = theme => ({
    gridContainerInPopup: {
        height: 'inherit',
        width: '1000px'
    },
    gridContainer: {
        height: 'inherit'
    },
    gridItem: {
        height: 'inherit'
    },
    closeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    },
    requestStatus: {
        height: 'inherit',
        display: 'table'
    },
    requestIcon: {
        verticalAlign: 'middle',
        fontSize: '60px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        right: "15px"
    }
});
var printWindow;
class ReportContainer extends React.Component {
    constructor(props) {
        super(props);
        this.currentReportTemplate = '';
        this.state = {
            isExistingReportLoading: false,
            isReportGenerating: false,
            openReportGenerationDialog: false,
            isErrorInReportGenerating: false,
            isReportStatusChanging: false,
            openReportStatusChangingDialog: false,
            isReportDeleting: false,
            openReportDeletingDialog: false,
            openReportViewerDialog: false,
            reportData: null,
            reportID: 0,
            isReportExists: false,
            openESignature: false,
            textUserNameValue: '',
            initialFetchedReports: null,
            selectedReport: null,
            changingState: '',
            apolloUser: '',
            generatedReportId: 0,
            openLockConfirmationDialog: false,
            lockPrompt: '',
            isRecordLocked: false
        };
    }

    componentDidMount() {
        this.getReports();
        this.getReportTemplates();
        this.getReportStatus();
        this.getApolloUser();
        this.getViewOnCreation();
        this.getReportSecurity();
    }

    componentWillUnmount() {
        const { onRecordLocked } = this.props;
        const { isRecordLocked } = this.state;
        if (printWindow !== undefined) {
            printWindow.close();
        }

        if (typeof onRecordLocked === 'function') {
            onRecordLocked(isRecordLocked);
        }
    }

    getReportSecurity() {
        reportApi.reportSecurity().then(reportSecurity => {
            this.setState({
                reportSecurity
            }, () => console.log(reportSecurity));
        }).catch(error => pushToError(error));
    }

    getViewOnCreation() {
        const { eventTableName, eventId } = this.props;

        reportApi.getViewOnCreation(eventTableName, eventId).then(viewOnCreationFlag => {
            this.setState({
                viewOnCreationFlag
            }, () => console.log(viewOnCreationFlag));
        }).catch(error => pushToError(error));
    }

    getApolloUser() {
        userManager.getUser().then((user) => {
            this.setState({
                apolloUser: user.profile.apollouserid
            });
        }).catch(error => pushToError(error));
    }

    getReports() {
        const { eventTableName, eventId } = this.props;

        this.setState({
            isExistingReportLoading: true,
            reports: null
        });

        reportApi.getReports(eventTableName, eventId).then(reports => {
            this.setState({
                reports,
                initialFetchedReports: reports,
                isExistingReportLoading: false
            });
        }).catch(error => pushToError(error));
    }

    getReportTemplates() {
        const { eventTableName, formName } = this.props;

        reportApi.getReportTemplates(eventTableName, formName).then(reportTemplates => {
            this.setState({
                reportTemplates
            });
        }).catch(error => pushToError(error));
    }

    getReportStatus() {
        reportApi.getReportStatus().then(reportStatusList => {
            console.log(reportStatusList);
            this.setState({
                reportStatusList
            });
        }).catch(error => pushToError(error));
    }

    getReport = (selectedReport) => {
        const { eventTableName, eventId } = this.props;
        this.setState({
            isExistingReportLoading: true
        });
        var reportId = selectedReport.reportID;
        reportApi.assertReportExists(eventTableName, eventId, reportId).then(isReportExists => {
            if (isReportExists) {
                reportApi.getReport(eventTableName, eventId, reportId, 'View').then(reportData => {
                    this.setState({
                        reportData,
                        reportID: reportId,
                        openReportViewerDialog: true,
                        isExistingReportLoading: false
                    });
                }).catch(error => pushToError(error));
            }
            else {
                alert('Report document ' + reportId + '.pdf does not exist.');
                this.setState({
                    isExistingReportLoading: false
                });
            }
        }).catch(error => pushToError(error));
    }

    onCreateReport = (selectedReportTemplate) => {
        const { reports, apolloUser } = this.state;
        console.log(reports);

        //Check if generated Report Exists. If not generate the new report.
        const found = reports.find(report => {
            return report.tag.templateId === selectedReportTemplate.tag.id;
        });

        this.currentReportTemplate = selectedReportTemplate.tag.name;

        if (found !== undefined) {
            if (found.reportStatus === "Final") {
                alert('Report `' + found.reportName + '` already exists and cannot be overwritten while its status is Final.');
            }
            else {
                this.setState({
                    isReportExists: true
                });
            }
        }
        else {
            reportApi.isEsignatureEnabled('Preliminary').then((isEsignatureEnabled) => {
                if (isEsignatureEnabled) {
                    this.setState({
                        changingState: 'Preliminary',
                        openESignature: true,
                        textUserNameValue: apolloUser,
                        isReportGenerating: true,
                        selectedReportTemplate
                    });
                }
                else {
                    this.onGenerateReport(selectedReportTemplate);
                }
            }).catch(error => pushToError(error));
        }
    }

    //Execute this method on Re-generating the report.
    onReGenerateReport = (selectedReportTemplate) => {
        const { apolloUser } = this.state;
        reportApi.isEsignatureEnabled('Preliminary').then((isEsignatureEnabled) => {
            if (isEsignatureEnabled) {
                this.setState({
                    changingState: 'Preliminary',
                    openESignature: true,
                    textUserNameValue: apolloUser,
                    isReportGenerating: true,
                    selectedReportTemplate
                });
            }
            else {
                this.onGenerateReport(selectedReportTemplate);
            }
        }).catch(error => pushToError(error));
    }

    //This is the main report generation logic, which gets called from two places - 
    //1. on '+' button click in New Report grid and 2. from Existing Report dialog.
    onGenerateReport = (selectedReportTemplate) => {
        const { eventTableName, formName, eventId } = this.props;
        const { viewOnCreationFlag, reports } = this.state;
        this.setState({
            isReportGenerating: true,
            openReportGenerationDialog: true
        });

        var rptId = 0;
        this.currentReportTemplate = selectedReportTemplate.tag.name;
        reportApi.createRequested(eventTableName, formName, eventId, selectedReportTemplate.tag.name, selectedReportTemplate.tag.id).then((report) => {
            console.log(selectedReportTemplate);
            console.log(report);
            
            if (report === null) {
                alert('Error during creation of report. Please check the settings in SSSettings table Or MS-Office is installed on server.');
                this.setState({
                    isReportGenerating: false,
                    isErrorInReportGenerating: true
                }, () => setTimeout(() => {
                    this.setState({
                        isErrorInReportGenerating: false,
                        openReportGenerationDialog: false
                    })
                }, 1000))
            }
            else {
                this.setState({
                    isReportGenerating: false
                }, () => {
                    this.getReports();
                    setTimeout(() => {
                        this.setState({
                            openReportGenerationDialog: false
                        })
                    }, 1000);

                    //Below condition is written to get Report ID after Report re-generation.
                    if (report.id === 0) {
                        const foundReport = reports.find(rpt => {
                            return rpt.tag.templateId === selectedReportTemplate.tag.id;
                        });

                        if (foundReport !== undefined) {
                            rptId = foundReport.reportID;
                        }
                    }
                    else {
                        rptId = report.id
                    }

                    this.setState({
                        generatedReportId: rptId
                    }, () => {
                        //View report on creation logic here in below condition.
                        if (viewOnCreationFlag) {
                            this.setState({
                                isExistingReportLoading: true
                            });

                            if (rptId > 0) {
                                reportApi.getReport(eventTableName, eventId, rptId, 'View').then(reportData => {
                                    this.setState({
                                        reportData,
                                        reportID: rptId,
                                        openReportViewerDialog: true,
                                        isExistingReportLoading: false
                                    });
                                }).catch(error => pushToError(error));
                            }
                        }
                    });
                });
            }
        }).catch(error => pushToError(error));
    }

    onResetGeneratedReportId = () => {
        this.setState({
            generatedReportId: 0
        });
    }

    onDeleteReport = (item) => {
        const { eventTableName, eventId } = this.props;
        this.currentReportTemplate = item.reportName;

        this.setState({
            isReportDeleting: true,
            openReportDeletingDialog: true
        });
        reportApi.ensureReportAccessRequested(eventTableName, eventId, item.reportID).then((isProcessing) => {
            console.log(isProcessing);
            if (isProcessing === false) {
                reportApi.deleteRequested(eventTableName, eventId, item.reportID).then(() => {
                    console.log(true);

                    this.setState({
                        isReportDeleting: false
                    }, () => {
                        this.getReports();
                        setTimeout(() => {
                            this.setState({
                                openReportDeletingDialog: false
                            })
                        }, 1000);
                    });
                }).catch(error => pushToError(error));
            }
            else {
                alert('The Report is currently being created in the background. Please wait for this process to complete...');
            }
        }).catch(error => pushToError(error));
    }

    onOpenReport = (selectedReport) => {
        console.log(selectedReport);
        this.getReport(selectedReport);
    }

    onPrintReport = (selectedReport) => {
        const { eventTableName, eventId } = this.props;

        var reportId = selectedReport.reportID;
        reportApi.assertReportExists(eventTableName, eventId, reportId).then(isReportExists => {
            if (isReportExists) {
                reportApi.getReport(eventTableName, eventId, reportId, 'Print').then(reportData => {
                    this.setState({
                        reportData
                    }, () => {
                        if (reportData !== null) {
                            this.handlePrint(reportData)
                        }
                    });
                }).catch(error => pushToError(error));
            }
            else {
                alert('Report document ' + reportId + '.pdf does not exist.');
            }
        })
    }

    handlePrint = (reportData) => {
        var winparams = 'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,' +
            'resizable,screenX=50,screenY=50,width=850,height=1050';

        var htmlPop;
        if (reportData.length > 1) {
            htmlPop = '<table border=0 width=100% height=750>'
                + '<tr><td height=10>Report: '
                + (reportData[0].reportFileName)
                + '.pdf</td><td height=10>Report: '
                + (reportData[1].reportFileName)
                + '.pdf</td></tr>'
                + ' <tr><td>'
                + '<tr><td><embed width=100% height=100%'
                + ' type="application/pdf"'
                + ' src="data:application/pdf;base64,'
                + (reportData[0].reportFileData)
                + '"></embed></td>'
                + '<td><embed width=100% height=100%'
                + ' type="application/pdf"'
                + ' src="data:application/pdf;base64,'
                + (reportData[1].reportFileData)
                + '"></embed></td></tr></table>';
        }
        else {
            htmlPop = '<table border=0 width=100% height=750>'
                + '<tr><td height=10>Report: '
                + (reportData[0].reportFileName)
                + '.pdf</td></tr>'
                + ' <tr><td>'
                + '<tr><td><embed width=100% height=100%'
                + ' type="application/pdf"'
                + ' src="data:application/pdf;base64,'
                + (reportData[0].reportFileData)
                + '"></embed></td>'
                + '</tr></table>';
        }

        if (printWindow !== undefined) {
            printWindow.close();
        }
        printWindow = window.open("", "PDF", winparams);
        printWindow.document.write(htmlPop);
    };

    closeReportViewerDialog = () => {
        this.setState({
            openReportViewerDialog: false
        });
    }

    onStatusChanging = (selectedReport, changedState) => {
        const { eventTableName, eventId } = this.props;
        const { apolloUser, initialFetchedReports, reportSecurity } = this.state;
        console.log(changedState);
        this.currentReportTemplate = selectedReport.original.reportName;

        //If tried to change Report Status to Final and user has no privileges, then it will prompt with message of no sufficient privileges to finalize reports.
        if (changedState === 'Final' && !reportSecurity.canFinalize) {
            alert('You do not have sufficient privileges to finalize reports.');

            this.setState({
                reports: null
            }, () => this.setState({ reports: initialFetchedReports }));

            return;
        }

        //If tried to change Report Status from Final to other status and user has no privileges, then it will prompt with message of no sufficient privileges to unfinalize reports.
        if (selectedReport.original.reportStatus === 'Final' && !reportSecurity.canUnfinalize) {
            alert('You do not have sufficient privileges to unfinalize reports.');

            this.setState({
                reports: null
            }, () => this.setState({ reports: initialFetchedReports }));

            return;
        }

        reportApi.isEsignatureEnabled(changedState).then((isEsignatureEnabled) => {
            console.log(isEsignatureEnabled);
            if (isEsignatureEnabled) {
                this.setState({
                    changingState: changedState,
                    openESignature: true,
                    textUserNameValue: apolloUser,
                    isReportStatusChanging: true
                });
            }
            else {
                this.setState({
                    isReportStatusChanging: true,
                    openReportStatusChangingDialog: true,
                    openESignature: false
                });
                reportApi.ensureReportAccessRequested(eventTableName, eventId, selectedReport.original.reportID).then((isProcessing) => {
                    console.log(isProcessing);
                    if (!isProcessing) {
                        reportApi.statusChangeRequested(eventTableName, eventId, selectedReport.original.reportID, changedState, apolloUser).then(() => {
                            console.log(true);

                            this.setState({
                                isReportStatusChanging: false
                            }, () => {
                                setTimeout(() => {
                                    this.setState({
                                        openReportStatusChangingDialog: false
                                    })
                                }, 1000);
                                reportApi.getReports(eventTableName, eventId).then(reports => {
                                    this.setState({
                                        reports,
                                        initialFetchedReports: reports
                                    }, () => {
                                        //Upon changing Report Status to Final, Check whether event records locks based on settings in 'SS_Patient_Reports' table.
                                        if (changedState === 'Final') {
                                            this.OnFinalize(eventTableName, eventId, selectedReport.original.reportID);
                                        }
                                    });
                                }).catch(error => pushToError(error));
                            });
                        }).catch(error => pushToError(error));
                    }
                    else {
                        this.setState({
                            isReportStatusChanging: false
                        });
                        alert('The Report is currently being created in the background. Please wait for this process to complete...');
                    }
                }).catch(error => pushToError(error));
            }
        }).catch(error => pushToError(error));
    }

    authenticateESignature = () => {
        const { eventTableName, eventId } = this.props;
        const { selectedReport, initialFetchedReports, changingState, isReportGenerating, isReportStatusChanging, selectedReportTemplate } = this.state;

        var userName = this.state.textUserNameValue;
        var password = this.state.textPasswordValue === undefined ? ' ' : this.state.textPasswordValue;
        var changedState = changingState;
        reportApi.eSignatureAuthenticated(userName, password).then((isESignatureAuthenticated) => {
            console.log(isESignatureAuthenticated);
            if (isESignatureAuthenticated === true) {
                if (isReportStatusChanging) {
                    this.setState({
                        isReportStatusChanging: true,
                        openReportStatusChangingDialog: true
                    });
                    reportApi.ensureReportAccessRequested(eventTableName, eventId, selectedReport.original.reportID).then((isProcessing) => {
                        console.log(isProcessing);
                        if (!isProcessing) {
                            reportApi.statusChangeRequested(eventTableName, eventId, selectedReport.original.reportID, changingState, userName).then(() => {
                                console.log(true);

                                this.setState({
                                    isReportStatusChanging: false,
                                    openESignature: false,
                                    changingState: ''
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            openReportStatusChangingDialog: false
                                        })
                                    }, 1000);
                                    reportApi.getReports(eventTableName, eventId).then(reports => {
                                        this.setState({
                                            reports,
                                            initialFetchedReports: reports
                                        }, () => {
                                            //Upon changing Report Status to Final, Check whether event records locks based on settings in 'SS_Patient_Reports' table.
                                            if (changedState === 'Final') {
                                                this.OnFinalize(eventTableName, eventId, selectedReport.original.reportID);
                                            }
                                        });
                                    }).catch(error => pushToError(error));
                                });
                            }).catch(error => pushToError(error));
                        }
                        else {
                            this.setState({
                                isReportStatusChanging: false,
                                changingState: ''
                            });
                            alert('The Report is currently being created in the background. Please wait for this process to complete...');
                        }
                    }).catch(error => pushToError(error));
                }

                if (isReportGenerating) {
                    this.onGenerateReport(selectedReportTemplate);
                    this.setState({
                        openESignature: false,
                        changingState: ''
                    });
                }
            }
            else {
                alert('Invalid username or password.');
            }
        }).catch(error => pushToError(error));
    }

    OnFinalize = (eventTableName, eventId, reportId) => {
        reportApi.checkForLockOnFinalize(eventTableName, eventId, reportId).then((RecordReportLockDto) => {
            console.log(RecordReportLockDto);

            if (RecordReportLockDto !== null) {
                if (RecordReportLockDto.hasPromptOnLock) {
                    this.setState({
                        openLockConfirmationDialog: true,
                        lockPrompt: RecordReportLockDto.lockPrompt
                    });
                }
                else {
                    if (RecordReportLockDto.canLock) {
                        this.lockRecord();
                    }
                }
            }
        }).catch(error => pushToError(error));
    }

    lockRecord = () => {
        const { eventTableName, eventId } = this.props;
        reportApi.lockOnFinalize(eventTableName, eventId).then((locked) => {
            console.log(locked);
            this.setState({
                openLockConfirmationDialog: false,
                isRecordLocked: locked
            });
        }).catch(error => pushToError(error));
    }

    closeLockConfirmationDialog = () => {
        this.setState({
            openLockConfirmationDialog: false,
            isRecordLocked: false
        });
    }

    onStatusChange = (selectedReport, changedState) => {
        console.log(changedState);
        console.log(selectedReport);
        this.setState({
            selectedReport
        });

        this.onStatusChanging(selectedReport, changedState);
    }

    closeESignatureDialog = () => {
        //Set report status to original status upon Cancel of eSignature.
        const { initialFetchedReports } = this.state;
        this.setState({
            openESignature: false,
            reports: null
        }, () => this.setState({ reports: initialFetchedReports }));
    }

    closeProgressDialog = () => {
        this.setState({
            isReportGenerating: false
        });
    }

    closeReportExistsDialog = () => {
        this.setState({
            isReportExists: false
        });
    }

    handleTextUserNameChange = (e) => {
        this.setState({
            textUserNameValue: e.target.value
        });
    }

    handleTextPasswordChange = (e) => {
        this.setState({
            textPasswordValue: e.target.value
        });
    }

    render() {
        const { classes } = this.props;
        const { requestStatus, requestIcon } = classes;
        const { reports, reportTemplates, isExistingReportLoading, isReportGenerating, openReportGenerationDialog, isErrorInReportGenerating, openReportViewerDialog, reportData, reportStatusList, isReportExists, isReportDeleting, openReportDeletingDialog, isReportStatusChanging, openReportStatusChangingDialog, openESignature, generatedReportId, reportSecurity, openLockConfirmationDialog, lockPrompt } = this.state;

        let selectedReportID = 0;
        let selectedReportBase64STR = '';
        let addendumReportID = 0;
        let addendumBase64STR = '';
        if (reportData !== null) {
            if (reportData.length > 1) {
                selectedReportID = reportData[0].reportFileName;
                selectedReportBase64STR = reportData[0].reportFileData;

                addendumReportID = reportData[1].reportFileName;
                addendumBase64STR = reportData[1].reportFileData;
            }
            else {
                selectedReportID = reportData[0].reportFileName;
                selectedReportBase64STR = reportData[0].reportFileData;
            }
        }

        return (
            <>
                <GridContainer spacing={4} className={classes.gridContainer}>
                    <GridItem xs={12} style={{ height: '50%' }}>
                        <ReportSelector
                            reports={reports}
                            isExistingReportLoading={isExistingReportLoading}
                            onOpenReport={this.onOpenReport}
                            reportStatusList={reportStatusList}
                            onStatusChange={this.onStatusChange}
                            onDeleteReport={this.onDeleteReport}
                            generatedReportId={generatedReportId}
                            onResetGeneratedReportId={this.onResetGeneratedReportId}
                            onPrintReport={this.onPrintReport}
                            reportSecurity={reportSecurity}
                        />
                    </GridItem>
                    <GridItem xs={12} style={{ height: '50%' }}>
                        <ReportTemplateSelector
                            reportTemplates={reportTemplates}
                            onCreateReport={this.onCreateReport}
                            isReportExists={isReportExists}
                            onGenerateReport={this.onReGenerateReport}
                            closeReportExistsDialog={this.closeReportExistsDialog}
                            isExistingReportLoading={isExistingReportLoading}
                        />
                    </GridItem>
                </GridContainer>

                <Dialog open={openReportViewerDialog} maxWidth="xl" onClose={this.closeReportViewerDialog} disableBackdropClick>
                    <Close
                        className={classes.closeIcon}
                        onClick={this.closeReportViewerDialog}
                    />
                    <DialogTitle>
                        <Typography className={classes.title} align='center' variant='h5'>{'Report Viewer'}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        {
                            reportData !== null ?
                                (
                                    reportData.length > 1 ?
                                        (
                                            <>
                                                <GridContainer className={classes.gridContainer}>
                                                    <GridItem xs={7} >
                                                        <ReportViewer
                                                            selectedReportID={selectedReportID}
                                                            base64STR={selectedReportBase64STR}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={4} >
                                                        <ReportViewer
                                                            selectedReportID={addendumReportID}
                                                            base64STR={addendumBase64STR}
                                                        />
                                                    </GridItem>
                                                </GridContainer>
                                            </>
                                        ) :
                                        (
                                            <ReportViewer
                                                selectedReportID={selectedReportID}
                                                base64STR={selectedReportBase64STR}
                                            />
                                        )
                                )
                                :
                                (
                                    <ReportViewer
                                        selectedReportID={selectedReportID}
                                        base64STR={selectedReportBase64STR}
                                    />
                                )
                        }
                    </DialogContent>
                    <MuiDialogActions>
                        <Button color="primary" variant='contained' onClick={this.closeReportViewerDialog}>
                            Close
                        </Button>
                    </MuiDialogActions>
                </Dialog>

                <Dialog open={openReportGenerationDialog} maxWidth="xs" disableBackdropClick>
                    <DialogContent>
                        {
                            isReportGenerating ? (
                                <>
                                    <CircularProgress size={60} thickness={7} />
                                    <Typography className={classes.title} align='center' variant='h5'>{'  Creating Report `' + this.currentReportTemplate + '`...  '}</Typography>
                                </>)
                                :
                                (
                                    isErrorInReportGenerating ?
                                        (
                                            <>
                                                <div className={requestStatus}>
                                                    <div className={requestIcon}>
                                                        <ErrorIcon color='error' fontSize='inherit' />
                                                    </div>
                                                </div>
                                                <Typography className={classes.title} align='center' variant='h5'>{'  ERROR: In Report Generation.  '}</Typography>
                                            </>

                                        ) :
                                        (
                                            <>
                                                <div className={requestStatus}>
                                                    <div className={requestIcon}>
                                                        <DoneIcon color='primary' fontSize='inherit' />
                                                    </div>
                                                </div>
                                                <Typography className={classes.title} align='center' variant='h5'>{'  Report Generated: `' + this.currentReportTemplate + '`  '}</Typography>
                                            </>
                                        )
                                )
                        }
                    </DialogContent>
                </Dialog>
                <Dialog open={openReportDeletingDialog} maxWidth="xs" disableBackdropClick>
                    <DialogContent>
                        {
                            isReportDeleting === true ? (
                                <>
                                    <CircularProgress size={60} thickness={7} />
                                    <Typography className={classes.title} align='center' variant='h5'>{'  Deleting Report `' + this.currentReportTemplate + '`...  '}</Typography>
                                </>)
                                :
                                (
                                    <>
                                        <div className={requestStatus}>
                                            <div className={requestIcon}>
                                                <DoneIcon color='primary' fontSize='inherit' />
                                            </div>
                                        </div>
                                        <Typography className={classes.title} align='center' variant='h5'>{'  Report Deleted: `' + this.currentReportTemplate + '`  '}</Typography>
                                    </>
                                )
                        }
                    </DialogContent>
                </Dialog>
                <Dialog open={openReportStatusChangingDialog} maxWidth="xs" disableBackdropClick>
                    <DialogContent>
                        {
                            isReportStatusChanging === true ? (
                                <>
                                    <CircularProgress size={60} thickness={7} />
                                    <Typography className={classes.title} align='center' variant='h5'>{'  Changing Report Status for `' + this.currentReportTemplate + '`...  '}</Typography>
                                </>)
                                :
                                (
                                    <>
                                        <div className={requestStatus}>
                                            <div className={requestIcon}>
                                                <DoneIcon color='primary' fontSize='inherit' />
                                            </div>
                                        </div>
                                        <Typography className={classes.title} align='center' variant='h5'>{'  Report Status Changed: `' + this.currentReportTemplate + '`  '}</Typography>
                                    </>
                                )
                        }
                    </DialogContent>
                </Dialog>
                <Dialog open={openESignature} aria-labelledby="form-dialog-title" disableBackdropClick>
                    <Close
                        className={classes.closeIcon}
                        onClick={this.closeESignatureDialog}
                    />
                    <DialogTitle id="form-dialog-title">E-Signature</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Sign Report: Enter your user name and password to electronically sign the report.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="inputUserName"
                            label="User Name"
                            type="text"
                            fullWidth
                            value={this.state.textUserNameValue} onChange={this.handleTextUserNameChange}
                        />
                        <TextField
                            margin="dense"
                            id="inputPassword"
                            label="Password"
                            type="password"
                            fullWidth
                            value={this.state.textPasswordValue} onChange={this.handleTextPasswordChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.authenticateESignature} color="primary">
                            Ok
                        </Button>
                        <Button onClick={this.closeESignatureDialog} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openLockConfirmationDialog} aria-labelledby="form-dialog-title" disableBackdropClick>
                    <Close
                        className={classes.closeIcon}
                        onClick={this.closeLockConfirmationDialog}
                    />
                    <DialogTitle id="form-dialog-title">Lock Record</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {lockPrompt}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.lockRecord} color="primary">
                            Yes
                        </Button>
                        <Button onClick={this.closeLockConfirmationDialog} color="primary">
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}
export default withStyles(styles)(ReportContainer);