import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DesktopIcon from '@material-ui/icons/DesktopWindows';
import PhoneIcon from '@material-ui/icons/Smartphone';
import React from 'react';
import { withRouter } from 'react-router';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardHeader from '.../assets/components/Card/CardHeader';
import ConfirmationButton from '.../assets/components/CustomButtons/ConfirmationButton';
import SplitButton from '.../assets/components/CustomButtons/SplitButton';
import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import FormControlBar from '.../components/features/forms/FormControlBar';
import LxCombobox from '.../components/formComponents/LxCombobox';
import LxTextField from '.../components/formComponents/LxTextField';
import FormHeaderPropertyEditor from './FormHeaderPropertyEditor';

const styles = theme => ({
    flexItem: {
        flex: '1 1 auto'
    },
    title: {
        paddingBottom: '10px'
    },
    button: {
        width: '120px'
    },
    card: {
        marginBottom: '20px'
    },
    eventAndViewContainer: {
        marginBottom: '6px'
    },
    formControlBar: {
        marginTop: '10px'
    },
    formHeaderSettingsButton: {
        position: 'absolute',
        bottom: '10px',
        right: '15px'
    }
});

class FormEditorHeader extends React.Component {
    saveForm = (schema) => () => {
        return this.props.saveForm(schema);
    };

    deleteForm = (schema) => () => {
        return this.props.deleteForm(schema);
    };

    copyForm = (schema) => () => {
        return this.props.copyForm(schema);
    };

    formToString = (form) => (
        form ?
            form.formDisplayName
            :
            ''
    )

    formToDisplayString = (form) => (
        form ?
            form.formDisplayName
            :
            ''
    )

    eventTableToString = (eventTable) => eventTable ? eventTable.eventTable : ''

    viewToString = (view) => (
        view ?
            view.viewName
            :
            ''
    )

    viewToDisplayString = (view) => (
        view ?
            view.viewName
            :
            ''
    )

    onFormSelect = (form) => {
        const { loadFormSchema } = this.props;
        loadFormSchema(form ? form.formName : null);
    }

    render() {
        const {
            classes,
            schema,
            onHeaderPropertyChange,
            updateFormTitle,
            formList,
            createNewForm,
            eventTables,
            updateEventTable,
            views,
            updateView,
            displaySize,
            updateDisplaySize,
            validationLoading,
            schemaValidation,
            validateFormSchema,
            formUnsavedChanges
        } = this.props;

        let formIndex = schema === null ?
            -1
            :
            formList.map(form => form.formName === null ? 'null' : form.formName.toLowerCase()).indexOf(schema.header.formName === null ? 'null' : schema.header.formName.toLowerCase());

        let eventTableIndex = schema === null || schema.header.eventTable === null ?
            -1
            :
            eventTables.map(eventTable => eventTable.eventTable.toLowerCase()).indexOf(schema.header.eventTable.toLowerCase());

        let viewIndex = schema === null || schema.header.viewName === null ?
            -1
            :
            views.map(view => view.viewName.toLowerCase()).indexOf(schema.header.viewName.toLowerCase());

        let displayNameError = (schema !== null && schema.formTitle === '')
            || (schemaValidation !== null && !schemaValidation.formDisplayNameUnique);
        let displayNameErrorMsg = '';
        if (schema !== null && schema.formTitle === '') {
            displayNameErrorMsg = 'Form name can not be empty';
        } else if (schemaValidation !== null && !schemaValidation.formDisplayNameUnique) {
            displayNameErrorMsg = 'Form name already exists, pick a unique name';
        }

        let saveButtonError =
            displayNameError
            || (schema !== null && schema.header.eventTable === null)
            || (schemaValidation !== null
                && (schemaValidation.sectionDisplayNames.length > 0
                    || schemaValidation.removedFields.length > 0
                    || schemaValidation.updatedFields.length > 0));

        return (
            <Card classes={{ card: classes.card }}>
                <CardHeader>
                    <Typography className={classes.title} align='center' variant='h6'>
                        {'MANAGE FORMS'}
                    </Typography>
                    <Divider />
                </CardHeader>
                <CardBody>
                    <GridContainer spacing={2}>
                        <GridItem className={classes.flexItem}>
                            <LxCombobox
                                selectedItem={formIndex >= 0 ? formList[formIndex] : schema}
                                items={formList}
                                placeholder='Search Forms...'
                                itemToString={this.formToString}
                                inputDisplayTemplate={this.formToDisplayString}
                                onChange={this.onFormSelect}
                                columns={[
                                    {
                                        title: 'Form Display Name',
                                        key: 'formDisplayName'
                                    },
                                    {
                                        title: 'Form Unique Name',
                                        key: 'formName'
                                    },
                                    {
                                        title: 'View Name',
                                        key: 'viewName'
                                    },
                                    {
                                        title: 'Event Table',
                                        key: 'eventTable'
                                    }
                                ]}
                                showConfirmationOnClear={formUnsavedChanges}
                                confirmationTitleOnClear='Clear form?'
                                confirmationTextOnClear={[
                                    'Are you sure you want to clear the form?',
                                    'Any unsaved changes to the current form will be lost.'
                                ]}
                                confirmationConfirmTextOnClear='Confirm'
                                fitInputWidth
                                keepInputOnExit
                            />
                        </GridItem>
                        <GridItem>
                            <ConfirmationButton
                                showConfirmation={schema !== null}
                                confirmationTitle='Create new form?'
                                confirmationText={[
                                    'Are you sure you want to create a new form?',
                                    'Any unsaved changes to the current form will be lost.'
                                ]}
                                confirmationConfirmText='Confirm'
                                className={classes.button}
                                onClick={createNewForm}
                                variant='outlined'
                                color='primary'
                            >
                                {'NEW FORM'}
                            </ConfirmationButton>
                        </GridItem>
                    </GridContainer>
                    <GridContainer spacing={9}>
                        <GridItem className={classes.flexItem}>
                            <LxTextField
                                disabled={schema === null}
                                error={displayNameError}
                                helperText={displayNameErrorMsg}
                                onBlur={validateFormSchema}
                                placeholder='Form Title'
                                value={schema !== null ? schema.header.formDisplayName : ''}
                                onChange={schema === null ? null : updateFormTitle}
                                label={'Display Name'}
                            />
                        </GridItem>
                        <GridItem>
                            <SplitButton
                                className={classes.button}
                                variant='outlined'
                                disabled={schema === null}
                                options={[
                                    {
                                        label: validationLoading ?
                                            <CircularProgress size={10} thickness={10} />
                                            :
                                            'SAVE',
                                        callback: this.saveForm(schema),
                                        error: saveButtonError,
                                        disabled: validationLoading || saveButtonError,
                                        showConfirmation: true,
                                        confirmationTitle: 'Save?',
                                        confirmationText: [
                                            'If you save it will overwrite older versions',
                                            'If the form is new it will be created'
                                        ],
                                        confirmationStatus: true
                                    },
                                    {
                                        label: 'DELETE',
                                        callback: this.deleteForm(schema),
                                        showConfirmation: true,
                                        confirmationTitle: 'Delete?',
                                        confirmationText: [
                                            'Are you sure you want to delete this form?',
                                            'You will not be able to get it back'
                                        ],
                                        confirmationStatus: true
                                    },
                                    {
                                        label: 'COPY',
                                        callback: this.copyForm(schema),
                                        showConfirmation: true,
                                        confirmationTitle: 'Copy?',
                                        confirmationText: [
                                            'This will create a copy of this form as a new form.',
                                            'Proceeding will discard unsaved changes to the this form.'
                                        ],
                                        confirmationStatus: true
                                    }
                                ]}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer spacing={2} className={classes.eventAndViewContainer}>
                        <GridItem className={classes.flexItem}>
                            <LxCombobox
                                disabled={schema === null}
                                selectedItem={eventTableIndex >= 0 ? eventTables[eventTableIndex] : null}
                                items={eventTables}
                                label={'Event Table'}
                                itemToString={this.eventTableToString}
                                inputDisplayTemplate={this.eventTableToString}
                                onChange={updateEventTable}
                                onBlur={validateFormSchema}
                                columns={[
                                    {
                                        title: 'Event Table',
                                        key: 'eventTable'
                                    }
                                ]}
                                fitInputWidth
                                keepInputOnExit
                            />
                        </GridItem>
                        <GridItem className={classes.flexItem}>
                            <LxCombobox
                                disabled={schema === null}
                                selectedItem={viewIndex >= 0 ? views[viewIndex] : null}
                                items={views.filter(view => eventTableIndex >= 0 ? view.tableName === eventTables[eventTableIndex].eventTable : true)}
                                label={'View'}
                                itemToString={this.viewToString}
                                inputDisplayTemplate={this.viewToDisplayString}
                                onChange={updateView}
                                onBlur={validateFormSchema}
                                columns={[
                                    {
                                        title: 'View Name',
                                        key: 'viewName'
                                    },
                                    {
                                        title: 'Event Table',
                                        key: 'tableName'
                                    }
                                ]}
                                fitInputWidth
                                keepInputOnExit
                            />
                        </GridItem>
                    </GridContainer>
                    <Divider />
                    <GridContainer>
                        <GridItem style={{ display: "flex", justifyContent: "flex-start" }}>
                            <FormControlBar
                                classes={{
                                    gridContainer: classes.formControlBar
                                }}
                                justify='center'
                                spacing={4}
                                components={[
                                    {
                                        type: 'button',
                                        label: <PhoneIcon />,
                                        toolTip: 'Small display layout',
                                        variant: displaySize === 'sm' ? 'contained' : 'outlined',
                                        onClick: updateDisplaySize('sm')
                                    },
                                    {
                                        type: 'button',
                                        label: <DesktopIcon />,
                                        toolTip: 'Large display layout',
                                        variant: displaySize === 'lg' ? 'contained' : 'outlined',
                                        onClick: updateDisplaySize('lg')
                                    }
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={10}>
                            <FormHeaderPropertyEditor
                                className={classes.formHeaderSettingsButton}
                                onChange={onHeaderPropertyChange}
                                formHeader={schema?.header}
                                disabled={!schema}
                            />
                        </GridItem>
                    </GridContainer>


                </CardBody>
            </Card>
        );
    }
}

export default withRouter(withStyles(styles)(FormEditorHeader));
/*
Temporarily removed medium layout from form editor
{
                                type: 'button',
                                label: <TabletIcon />,
                                toolTip: 'Medium display layout',
                                variant: displaySize === 'md' ? 'contained' : 'outlined',
                                onClick: updateDisplaySize('md')
                            },
 */