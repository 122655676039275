import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import FieldHeader from './helperComponents/FieldHeader';
import ValidationErrorIcon from './helperComponents/ValidationErrorIcon';

const styles = theme => ({
    inputRoot: {
        fontSize: theme.typography.fontSize,
        marginTop: '0px !important'
    },
    gridItem: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important"
    },
    colorError: {
        color: theme.palette.error.main
    },
    colorWarning: {
        color: theme.palette.warning.main
    },
    inputGridItem: {
        width: '100%'
    }
});

class LxFloatField extends React.Component {
    handleKeyPress = (event) => {
        let charCode = event.which ? event.which : event.keyCode;

        if (charCode > 31 && charCode !== 46 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        }

        return true;
    }

    handleBlur = (event) => {
        const { onBlur, value, defaultValue } = this.props;
        let newValue = event.target.value.toString();

        if (typeof onBlur === 'function') {
            let val = this.getValidatedFloat(value, newValue, false);
            let preStrValue = defaultValue === null ? '' : defaultValue.toString();
            if (preStrValue !== newValue) {
                return onBlur(val);
            }
        }
    }

    handleChange = (event) => {
        const { onChange, value } = this.props;
        let newValue = event.target.value.toString();

        if (typeof onChange === 'function') {
            let val = this.getValidatedFloat(value, newValue, true);
            return onChange(val);
        }
    }

    handleChangeFromEmrSelector = (value) => {
        const { disabled, onBlur, onChange } = this.props;
        if (!disabled) {
            if (typeof onChange === 'function') {
                onChange(value);
            }
            if (typeof onBlur === 'function') {
                onBlur(value);
            }
        }
    };

    getValidatedFloat = (currentValue, newValue, showAlert) => {
        const { min, max } = this.props;
        let floatRegex = /^(\d*(?:\.\d+)?)[.\d]*$/;

        if (newValue) {
            if (!newValue.match(floatRegex)) {
                if (showAlert) {
                    alert("Incorrect value format - '" + newValue + "'. The value must be in numeric format."); // TODO show the notification as a message above or below the field. Add a red border around the field.
                }
                newValue = currentValue;
            } else if (max && min && (newValue > max || newValue < min)) {
                if (showAlert) {
                    alert('The value: ' + newValue + ' is out of range. Range:' + min + ', +' + max);
                }
                newValue = currentValue;
            }
        }
        return newValue;
    }

    render() {
        const {
            classes,
            borderStyle,
            error,
            disabled,
            title,
            titleTooltip,
            descriptions,
            value,
            valueOptions,
            onBlur,
            defaultValue,
            helperText,
            hasMappings,
            tabIndex,
            validationErrorSeverity,
            autoFocus,
            forcedFocus,
            inputRef,
            fieldInformation,
            fieldInfo,
            inputOnly, metaDataKey, mappingElementsStatus, onUpdateMappingStatus, formLoadProgress, fhirPopupSelector, emrInfo
        } = this.props;

        return (
            <FormControl disabled={disabled} style={borderStyle}>
                {inputOnly ?
                    null
                    :
                    <GridContainer spacing={2} wrap="nowrap" alignItems="center">
                        <GridItem className={classes.gridItem}>
                            <FormLabel>
                                <Tooltip title={titleTooltip} placement="top">
                                    <Typography align="left" color='inherit' variant='body2'>
                                        {title}
                                    </Typography>
                                </Tooltip>
                            </FormLabel>
                        </GridItem>
                        <GridItem>
                            <FieldHeader
                                value={typeof value === 'undefined' ? defaultValue : value}
                                descriptions={descriptions}
                                valueOptions={valueOptions}
                                onValueSelect={this.handleChangeFromEmrSelector}
                                hasMappings={hasMappings}
                                error={error}
                                validationErrorSeverity={validationErrorSeverity}
                                helperText={helperText}
                                metaDataKey={metaDataKey}
                                mappingElementsStatus={mappingElementsStatus}
                                onUpdateMappingStatus={onUpdateMappingStatus}
                                defaultValue={defaultValue}
                                formLoadProgress={formLoadProgress}
                                fhirPopupSelector={fhirPopupSelector}
                                fieldInformation={fieldInformation}
                                fieldInfo={fieldInfo}
                                emrInfo={emrInfo}
                            />
                        </GridItem>
                    </GridContainer>
                }
                <GridContainer wrap="nowrap" alignItems="center">
                    <GridItem className={classes.inputGridItem}>
                        <Input
                            value={value === null ? '' : value}
                            defaultValue={defaultValue === null ? '' : defaultValue}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            fullWidth
                            type='number'
                            inputProps={{ tabIndex, autoFocus }}
                            inputRef={fieldInputRef => {
                                if (forcedFocus && fieldInputRef) {
                                    fieldInputRef.focus();
                                }
                                if (typeof inputRef === 'function') {
                                    inputRef(fieldInputRef);
                                }
                            }}
                            onKeyPress={this.handleKeyPress}
                            classes={{
                                root: classes.inputRoot
                            }}
                        />
                    </GridItem>
                    {inputOnly ?
                        <GridItem>
                            <ValidationErrorIcon
                                error={error}
                                validationErrorSeverity={validationErrorSeverity}
                                helperText={helperText}
                            />
                        </GridItem>
                        :
                        null
                    }
                </GridContainer>
                {error && helperText && validationErrorSeverity === 1 ?
                    <FormHelperText error={false} className={classes.colorError}>{helperText}</FormHelperText>
                    :
                    null
                }
            </FormControl>
        );
    }
}

LxFloatField.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    hasMappings: PropTypes.bool
};

LxFloatField.defaultProps = {
    disabled: false,
    error: false
};

export default withStyles(styles)(LxFloatField);