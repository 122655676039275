import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardFooter from '.../assets/components/Card/CardFooter';
import CardHeader from '.../assets/components/Card/CardHeader';
import ConfirmationButton from '.../assets/components/CustomButtons/ConfirmationButton';
import LxSearchableTable from '.../components/formComponents/LxSearchableTable';

const styles = theme => ({
    card: {
        height: '100%',
        display: 'block',
        marginTop: '10px'
    },
    cardBody: {
        height: 'calc(100% - 245px)'
    },
    divider: {
        marginTop: '15px'
    },
    cardFooter: {
        display: 'flex',
        bottom: '0px',
        width: '100%',
        alignitems: 'right',
        justifycontent: 'space-between'
    },
    buttonCell: {
        padding: '0px !important'
    },
    button: {
        padding: '0px 8px !important',
        height: '24px !important',
        display: 'flex'
    },
    editIcon: {
        flex: '0 0 auto'
    }
});

class StaffSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            staffRecord: null
        };
    }

    deleteStaff = (staffId) => {
        const { onDeleteStaff } = this.props;
        onDeleteStaff(staffId);
    }

    selectStaff = (staff) => {
        const { onStaffSelect } = this.props;
        onStaffSelect(staff);
    }

    handleChange = (staff) => {
        const { onHandleChange } = this.props;
        onHandleChange(staff);
    }

    staffRowClick = (staff) => {
        this.setState({
            staffRecord: staff
        });
    }

    render() {
        const { classes, onStaffEditorOpen, staffData, isLoading, recordContext, staffLookupSecurity } = this.props;
        const { staffRecord } = this.state;

        return (
            <Card className={classes.card}>
                <CardHeader>
                    <Typography className={classes.title} align='center' variant='h6'>
                        {'STAFF LOOKUP'}
                    </Typography>
                    <Divider />
                </CardHeader>

                <CardBody className={classes.cardBody}>
                    <LxSearchableTable
                        items={staffData !== null ? staffData : []}
                        columns={[
                            {
                                title: 'Staff Id',
                                key: 'staffId',
                                show: false
                            },
                            {
                                title: 'Active',
                                id: 'active',
                                key: (item) => {
                                    return item.active === true ? 'Yes' : 'No';
                                },
                                width: 60
                            },
                            {
                                title: 'First Name',
                                key: 'firstName',
                                width: 140
                            },
                            {
                                title: 'Middle Name',
                                key: 'middleName',
                                id: 'middleName',
                                width: 140
                            },
                            {
                                title: 'Last Name',
                                key: 'lastName',
                                id: 'lastName',
                                width: 140
                            },
                            {
                                title: 'Suffix',
                                key: 'suffix',
                                id: 'suffix',
                                width: 60
                            },
                            {
                                title: 'Provider ID',
                                key: 'providerID',
                                id: 'providerID',
                                width: 115
                            },
                            {
                                title: 'NPI',
                                key: 'npi',
                                width: 115
                            },
                            {
                                title: 'Specialty',
                                key: 'specialty',
                                width: 115
                            },
                            {
                                title: 'Address',
                                id: 'address1',
                                key: (item) => {
                                    return ((item.address1 !== null ? item.address1 : '') + ' ' + (item.address2 !== null ? item.address2 : '') + ' ' + (item.address3 !== null ? item.address3 : '') + ' ' + (item.address4 !== null ? item.address4 : '') + ' ' + (item.city !== null ? item.city : '') + ', ' + (item.state !== null ? item.state : '') + ' ' + (item.zipCode !== null ? item.zipCode : ''));
                                },
                                width: 115
                            },
                            {
                                title: 'Phone',
                                key: 'phone1',
                                width: 115
                            },
                            {
                                title: 'Role',
                                key: 'role',
                                width: 115
                            },
                            {
                                id: 'edit',
                                width: 65,
                                title: 'Edit',
                                resizable: false,
                                className: classes.buttonCell,
                                key: (item) => (
                                    <ConfirmationButton
                                        onClick={() => this.selectStaff(item)}
                                        className={classes.button}
                                        disabled={staffLookupSecurity !== undefined ? !staffLookupSecurity.edit : false}
                                    >
                                        <EditIcon className={classes.editIcon} />
                                    </ConfirmationButton>
                                )
                            },
                            {
                                id: 'delete',
                                width: 65,
                                resizable: false,
                                className: classes.buttonCell,
                                title: 'Delete',
                                key: (item) => (
                                    <ConfirmationButton
                                        className={classes.button}
                                        showConfirmation
                                        confirmationTitle='Permanently Delete Staff?'
                                        confirmationText={[
                                            'Are you sure you want to remove this staff?',
                                            'You will not be able to get it back.'
                                        ]}
                                        confirmationConfirmText='Delete'
                                        onClick={() => this.deleteStaff(item.staffId)}
                                        disabled={staffLookupSecurity !== undefined ? !staffLookupSecurity.delete : false}
                                    >
                                        <DeleteIcon />
                                    </ConfirmationButton>
                                )
                            }
                        ]}
                        searchKeys={[
                            'staffId',
                            'firstName',
                            'middleName',
                            'lastName',
                            'Suffix',
                            'ProviderId',
                            'npi',
                            'specialty',
                            'phone1',
                            'role'
                        ]}
                        onRowClick={this.staffRowClick}
                        selectedItem={staffRecord}
                        isLoading={isLoading}
                        title={'Available Staff'}
                    />
                    <Divider />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={onStaffEditorOpen}
                        disabled={isLoading || (staffLookupSecurity !== undefined ? !staffLookupSecurity.add : false)}
                    >
                        {'Add New Staff'}
                    </Button>
                    {
                        recordContext !== undefined ?
                            (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    disabled={isLoading}
                                    onClick={() => this.handleChange(staffRecord)}
                                >
                                    {'Apply'}
                                </Button>
                            )
                            :
                            null
                    }
                </CardFooter>
            </Card>
        );
    }
}

export default withStyles(styles)(StaffSelector);