import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import GridContainer from ".../assets/components/Grid/GridContainer";
import GridItem from ".../assets/components/Grid/GridItem";
import FormControlBar from '.../components/features/forms/FormControlBar';
import { formApi } from '.../utils/apiHelper';
import PatientEvents from './PatientEvents';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    patientInfo: {
        fontSize: '13px',
        fontWeight: 'normal',
        paddingLeft: "50px",
        paddingTop: "10px"
    }
});

function PatientEventsContainer(props) {
    const [formTables, setFormTables] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [moduleSecurity, setModuleSecurity] = React.useState();
    const [deleteWarningOpen, setDeleteWarningOpen] = React.useState(false);
    const { patientInfo, classes } = props;

    let reloadFormTables = () => {
        formApi.getFormTables(patientInfo.ssPatientId)
            .then(formTables => {
                setFormTables(formTables);
            });
    };

    React.useEffect(() => {
        formApi.getUserRightsOverPatientRecord()
            .then(data => {
                setModuleSecurity(data);
            });
        formApi.getFormTables(patientInfo.ssPatientId)
            .then(formTables => {
                setFormTables(formTables);
            }).then(() => {
                setIsLoading(false);
            });
    }, [patientInfo.ssPatientId]);

    let toggleRecordLock = (tableName, recordId, event) => {
        event.preventDefault();
        formApi.toggleRecordLock(tableName, recordId).then(() => {
            reloadFormTables();
        });
    };

    let deleteRecord = (tableName, recordId, event) => {
        event.preventDefault();
        formApi.deleteRecord(tableName, recordId).then(() => {
            reloadFormTables();
        });
    };

    let openDeleteWarning = (event) => {
        event.preventDefault();
        setDeleteWarningOpen(true);
    };

    let closeDeleteWarning = (shouldDelete) => () => {
        setDeleteWarningOpen(false);
        if (shouldDelete) {
            formApi.deletePatient(patientInfo.ssPatientId).then(() => {
                alert('Patient was successfully deleted');
                props.history.push('/');
            });
        }
    };

    let formControlBarComponents = [
        {
            type: 'button',
            label: 'DELETE PATIENT',
            showConfirmation: false,
            onClick: openDeleteWarning,
            disabled: !moduleSecurity?.allowDelete
        }
    ];

    return isLoading
        ? <CircularProgress size={60} thickness={7} />
        : <GridContainer justify={'space-evenly'} alignItems={'flex-start'} alignContent={'flex-start'} spacing={4}>
            <GridItem xs={10}>
                <div className={classes.patientInfo} style={{ textAlign: "left" }}>
                {'\n'} MRN: {patientInfo.patientId } &nbsp;&nbsp;&nbsp;&nbsp; Name: {patientInfo.lastName},&nbsp; {patientInfo.firstName}&nbsp;&nbsp;&nbsp;&nbsp; DOB: {moment(patientInfo.dateOfBirth).format('MM/DD/YYYY')}
                </div>
            </GridItem>
            <GridItem xs={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                <FormControlBar
                    components={formControlBarComponents}
                />
            </GridItem>
            {formTables.map(formTable => (
                <GridItem key={formTable.tableName} xs={12} sm={6} lg={4}>
                    <PatientEvents
                        formTable={formTable}
                        routeUrl={`/patient/${patientInfo.patientId}/forms`}
                        toggleRecordLock={toggleRecordLock}
                        deleteRecord={deleteRecord}                        
                        ssPatientId={patientInfo.ssPatientId}
                    />
                </GridItem>
            )
            )
            }
            <Dialog open={deleteWarningOpen} onClose={closeDeleteWarning(false)}>
                <DialogTitle>Permanently Delete Patient?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this patient and all associated events?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteWarning(false)}>
                        Cancel
                    </Button>
                    <Button onClick={closeDeleteWarning(true)}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </GridContainer>;
}

export default withStyles(styles)(withRouter(connect((state) => ({ patientInfo: state.demographics, recordInfo: state.recordInfo }))(PatientEventsContainer)));

/**/
