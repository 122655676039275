import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import GridContainer from 'assets/components/Grid/GridContainer';
import React from 'react';

import GridItem from '.../assets/components/Grid/GridItem';
import EmrValueSelector from './EmrValueSelector';
import FieldInfoButton from "./FieldInfoButton";
import ValidationErrorIcon from './ValidationErrorIcon';

const styles = theme => ({
    gridItem: {
        height: "18px",
        paddingTop: "0px !important",
        paddingBottom: "0px !important"
    }
});

function FieldHeader(props) {
    const { className, classes, title, descriptions, fieldInformation, fieldInfo, value, hasMappings, valueOptions, onValueSelect, error, validationErrorSeverity, helperText, align = "left", metaDataKey, mappingElementsStatus, onUpdateMappingStatus, defaultValue, formLoadProgress, fhirPopupSelector,emrInfo } = props;

    return (
        <GridContainer className={className} spacing={2} wrap="nowrap" alignItems="center">
            {title ?
                <GridItem className={classes.gridItem}>
                    <Typography align={align} color='inherit' variant='body2'>
                        {title}
                    </Typography>
                </GridItem>
                :
                null
            }
            <GridItem className={classes.gridItem}>
                {(descriptions || fieldInformation) ?
                    <GridItem className={classes.gridItem}>
                        <FieldInfoButton descriptions={descriptions} fieldInformation={fieldInformation} fieldInfo={fieldInfo} />
                    </GridItem>
                    :
                    null
                }
            </GridItem>
            {hasMappings ?
                <GridItem className={classes.gridItem}>
                    {formLoadProgress === 100 ?
                        <EmrValueSelector value={value} hasMappings={hasMappings} valueOptions={valueOptions} onValueSelect={onValueSelect} metaDataKey={metaDataKey} mappingElementsStatus={mappingElementsStatus} onUpdateMappingStatus={onUpdateMappingStatus} defaultValue={defaultValue} formLoadProgress={formLoadProgress} fhirPopupSelector={fhirPopupSelector} emrInfo={emrInfo} /> :
                        null
                    }
                </GridItem>
                : null
            }
            {
                <ValidationErrorIcon
                    error={error}
                    validationErrorSeverity={validationErrorSeverity}
                    helperText={helperText}
                />
            }
        </GridContainer>
    );
}

export default withStyles(styles)(FieldHeader);