import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from '@material-ui/icons/Error';
import cx from "classnames";
import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from "react-router-dom";

import sidebarStyle from '../../jss/material-dashboard-pro-react/components/sidebarStyle';

class SidebarLinkItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingViews: true,
            dropDownOpen: SidebarLinkItem.activeRoute(SidebarLinkItem.convertPath(this.props.route.path, this.props.patientInfo), this.props.location.pathname),
            views: [],
            errorGettingViews: false,
            currentPath: this.props.location.pathname
        };

        this.componentMounted = false;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.location.pathname !== state.currentPath) {
            if (SidebarLinkItem.activeRoute(SidebarLinkItem.convertPath(props.route.path, props.patientInfo), props.location.pathname)) {
                return {
                    dropDownOpen: true,
                    currentPath: props.location.pathname
                };
            } else {
                return {
                    dropDownOpen: false,
                    currentPath: props.location.pathname
                };
            }
        } else {
            return null;
        }
    }

    static convertPath = (originalPath, patientInfo) => {
        if (typeof patientInfo !== 'undefined' && patientInfo !== null) {
            return originalPath.replace(':mrn', patientInfo.patientId);
        } else {
            return originalPath;
        }
    }

    // verifies if routeName is the one active (in browser input)
    static activeRoute = (routeName, pathname) => {
        if (routeName === '/') {
            return pathname === '/' ? true : false;
        }
       
        if (routeName === pathname || (routeName.includes('forms') && routeName.split('/')[4]===undefined)) {
           return true;
        } else {
             return false;
        }
    }

    componentDidMount() {
        this.componentMounted = true;
        this.updateViews();
    }

    componentWillUnmount() {
        this.componentMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.updateViews();
        }
    }

    updateViews = () => {
        const { route } = this.props;

        if (typeof route.views !== 'undefined') {
            Promise.resolve(route.views).then(views => {
                if (this.componentMounted) {
                    this.setState({
                        loadingViews: false,
                        errorGettingViews: false,
                        views
                    });
                }
            }).catch(err => {
                if (this.componentMounted) {
                    this.setState({
                        loadingViews: false,
                        errorGettingViews: true,
                        errorMessage: err.message
                    });
                }
            });
        }
    }

    openCollapse = () => {
        this.setState({
            dropDownOpen: !this.state.dropDownOpen
        });
    }

    render() {
        const { route, collapsedItem = false, classes, rtlActive, miniActive, color, patientInfo } = this.props;
        const { loadingViews, dropDownOpen, views = [], errorGettingViews, errorMessage } = this.state;

        let activeRoute = SidebarLinkItem.activeRoute(SidebarLinkItem.convertPath(route.path, patientInfo), this.props.location.pathname);

        const navLinkClasses = cx({
            [classes.itemLink]: true,
            [classes.collapseActive]: false,
            [classes[color]]: activeRoute
        });

        const itemText = cx({
            [classes.itemText]: true,
            [classes.itemTextMini]: miniActive,
            [classes.itemTextMiniRTL]: rtlActive && miniActive,
            [classes.itemTextRTL]: rtlActive
        });

        const collapseItemText = cx({
            [classes.collapseItemText]: true,
            [classes.collapseItemTextMini]: miniActive,
            [classes.collapseItemTextMiniRTL]: rtlActive && miniActive,
            [classes.collapseItemTextRTL]: rtlActive
        });

        const collapseItemMini = cx({
            [classes.collapseItemMini]: true,
            [classes.collapseItemMiniRTL]: rtlActive
        });

        const itemIcon = cx({
            [classes.itemIcon]: true,
            [classes.itemIconRTL]: rtlActive
        });

        const caret = cx({
            [classes.caret]: true,
            [classes.collapseCaret]: collapsedItem,
            [classes.caretRTL]: rtlActive
        });

        let listItemSecondary;

        if (loadingViews && typeof route.views !== 'undefined') {
            listItemSecondary = <CircularProgress className={classes.loading} size={10} thickness={10} />;
        } else if (errorGettingViews) {
            listItemSecondary = (
                <Tooltip placement='right' title={errorMessage}>
                    <ErrorIcon className={classes.error} color='error' fontSize='inherit' />
                </Tooltip>
            );
        } else if (views.length > 0) {
            listItemSecondary = <b className={caret + " " + (dropDownOpen ? classes.caretActive : "")} />;
        } else {
            listItemSecondary = null;
        }

        return (
            <ListItem className={collapsedItem ? classes.collapseItem : classes.item}>
                <NavLink
                    to={SidebarLinkItem.convertPath(route.path, patientInfo)}
                    isActive={() => route.link}
                    className={navLinkClasses}
                    onClick={(event) => {
                        if (!route.link) {
                            event.preventDefault();
                        }
                        this.openCollapse();
                    }}
                >

                    {typeof route.icon === 'undefined' && miniActive ?
                        <span className={collapseItemMini}>
                            {route.mini}
                        </span> :
                        <ListItemIcon className={itemIcon}>
                            {route.icon}
                        </ListItemIcon>
                    }

                    <ListItemText
                        primary={route.name}
                        secondary={listItemSecondary}
                        disableTypography
                        className={collapsedItem ? collapseItemText : itemText}
                    />
                </NavLink>
                {views.length > 0 ?
                    <Collapse in={dropDownOpen} unmountOnExit>
                        <List className={classes.list + " " + classes.collapseList}>
                            {views.map((route, key) =>
                                <SidebarLinkItemWrapper
                                    route={route}
                                    collapsedItem
                                    rtlActive={rtlActive}
                                    miniActive={miniActive}
                                    color={color}
                                    key={`${route.name}-${key}`}
                                />
                            )}
                        </List>
                    </Collapse>
                    :
                    null
                }
            </ListItem>
        );
    }
}


const mapStateToProps = (state) => ({
    patientInfo: state.demographics
});

const SidebarLinkItemWrapper = withStyles(sidebarStyle)(withRouter(connect(mapStateToProps, null)(SidebarLinkItem)));

export default SidebarLinkItemWrapper;