// ##############################
// // // Header styles
// #############################

import {
    containerFluid,
    dangerColor,
    defaultBoxShadow,
    defaultFont,
    infoColor,
    primaryColor,
    successColor,
    warningColor
} from "../../material-dashboard-pro-react";

const headerStyle = theme => ({
    appBar: {
        [theme.breakpoints.up("md")]: {
            backgroundColor: theme.palette.background.default
        },
        backgroundColor: 'transparent',
        boxShadow: "none",
        borderBottom: "0",
        marginBottom: "0",
        marginTop: `${theme.topBarOffset}px`,
        position: "fixed",
        width: "100%",
        zIndex: "1029",
        color: "#000",
        border: "0",
        borderRadius: "3px",
        transition: "all 150ms ease 0s",
        height: "16px",
        display: "block"
    },
    container: {
        ...containerFluid,
        minHeight: "auto",
        height: "16px"
    },
    flex: {
        flex: 1
    },
    pageTitle: {
        margin: "auto",
        textAlign: "left",
        marginLeft: '5px',
        fontSize: '14px',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px'
        }
    },
    title: {
        ...defaultFont,
        lineHeight: "30px",
        fontSize: "18px",
        borderRadius: "3px",
        textTransform: "none",
        color: "inherit",
        paddingTop: "0.625rem",
        paddingBottom: "0.625rem",
        "&:hover,&:focus": {
            background: "transparent"
        }
    },
    primary: {
        backgroundColor: primaryColor,
        color: "#FFFFFF",
        ...defaultBoxShadow
    },
    info: {
        backgroundColor: infoColor,
        color: "#FFFFFF",
        ...defaultBoxShadow
    },
    success: {
        backgroundColor: successColor,
        color: "#FFFFFF",
        ...defaultBoxShadow
    },
    warning: {
        backgroundColor: warningColor,
        color: "#FFFFFF",
        ...defaultBoxShadow
    },
    danger: {
        backgroundColor: dangerColor,
        color: "#FFFFFF",
        ...defaultBoxShadow
    },
    sidebarMinimize: {
        float: "left",
        paddingTop: `15px`,
        display: "block",
        color: "#555555"
    },
    sidebarMinimizeRTL: {
        padding: "0 15px 0 0 !important"
    },
    sidebarMiniIcon: {
        width: "20px",
        height: "17px"
    }
});

export default headerStyle;