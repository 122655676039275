import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from "react-router-dom";

import Card from ".../assets/components/Card/Card";
import CardBody from ".../assets/components/Card/CardBody";
import CardHeader from ".../assets/components/Card/CardHeader";
import regularFormsStyle from ".../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import PatientEventsItem from './PatientEventsItem';
import { formApi } from '.../utils/apiHelper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
    ...regularFormsStyle,
    cardSection: {
        marginBottom: '5px',
        marginTop: '20px'
    },
    scrollBar: {
        maxHeight: '35vh',
        position: 'relative',
        overflowY: 'auto',
        MsOverflowStyle: 'scrollbar'
    }
}));

const PatientEvents = (props) => {
    const { formTable, routeUrl, toggleRecordLock, deleteRecord, ssPatientId } = props;
    const [openSaveDialog, setOpenSaveDialog] = React.useState(false);
    const classes = useStyles();
    let renderListItem = (record) => <PatientEventsItem key={record.eventId} routeUrl={routeUrl} record={record} formTable={formTable} toggleRecordLock={toggleRecordLock} deleteRecord={deleteRecord} />;

    let addAndGetNewEventId = (eventTableName) => {
        formApi.addAndGetNewEventId(ssPatientId, eventTableName)
            .then(newEventId => {
                props.history.push(`${routeUrl}/${formTable.tableName}/${newEventId}/${(formTable.newRecordDefaultFormName !== undefined || formTable.newRecordDefaultFormName !== null) ? formTable.newRecordDefaultFormName : formTable.formHeaders[0].formName}`);
            });
    };

    let openSavePopup = () => {
        setOpenSaveDialog(true);
    };

    let closeSavePopup = () => {
        setOpenSaveDialog(false);
    };

    return (
        <>
            <Card>
                <CardHeader icon className={classes.cardSection}>
                    <h4 className={classes.cardTitle}>{formTable.description}</h4>
                </CardHeader>
                <CardBody>
                    <div className={classes.scrollBar}>
                        <Divider />
                        {(formTable.recordList.length > 0) ?
                            formTable.recordList.map((record) => renderListItem(record))
                            :
                            <div>
                                {'No records found, please create one.'}
                                <Divider />
                            </div>
                        }
                    </div>
                    <Button variant='contained' color='primary' className={classes.cardSection} onClick={() => openSavePopup()}>
                        {'Add New Event'}
                    </Button>
                </CardBody>
            </Card>
            <Dialog open={openSaveDialog} onClose={() => closeSavePopup()} classes={{ paper: classes.dialogPaper }} maxWidth={'xs'} disableBackdropClick>
                <DialogTitle>
                    {'Save?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText align='center' variant='h7'>
                        {'Saving will create a new event record in the Apollo database.'} <br /> {'Do you want to proceed?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='primary' onClick={() => addAndGetNewEventId(formTable.tableName)}>
                        {'Save'}
                    </Button>
                    <Button variant='contained' color='primary' onClick={() => closeSavePopup()}>
                        {'Cancel'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

PatientEvents.propTypes = {
    formTable: PropTypes.object.isRequired
};

export default withRouter(PatientEvents);
