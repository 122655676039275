import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { withStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import DynamicFormSection from './DynamicFormSection';
import DynamicFormSidePanel from './FormSidePanel/DynamicFormSidePanel';

const styles = theme => ({
    flexWrapper: {
        display: 'flex',
        flexDirection: 'row'
    },
    sidePanelFlex: {
        width: '265px',
        flex: '0 0 auto'
    },
    formBodyFlex: {
        flex: '1 0 auto'
    },
    disableForm: {
        opacity: 0.5,
        pointerEvents: 'none'
    }
});

const sortSectionsBySequence = (a, b) => a.sequence - b.sequence;

function DynamicForm(props) {
    const {
        formSchema, formMetaData, formData, sectionList, recordContext, settings,
        onBlur,onChange, onAddRecord, onDeleteRecords, setRecordCurrency,
        widgetDataCollection, triggerWidgetCall,
        executeCommandButton, triggerScroll,
        tabView, classes, isLocked, formSecurity, newEvent, enableFormEdit, mappingElementsStatus, onUpdateMappingStatus, formLoadProgress
    } = props;

    const [navigation, setNavigation] = React.useState({
        currentSectionName: formSchema.sections.sort(sortSectionsBySequence)[0].sectionName
    });

    useEffect(() => {
        window.onkeydown = (e) => {
            if ((e.keyCode === 219 || e.keyCode === 221) && e.ctrlKey) {
                e.preventDefault();
                let indexedSectionList = Object.getOwnPropertyNames(sectionList);
                let currentSectionIndex = -1;
                indexedSectionList.forEach((sectionName, index) => {
                    if (sectionName === navigation.currentSectionName) {
                        currentSectionIndex = index;
                    }
                });
                //bracketLeft keycode = 219
                if (e.keyCode === 219 && currentSectionIndex > 0) {
                    let lastSectionName = indexedSectionList[currentSectionIndex - 1];
                    onSectionSelect(lastSectionName);
                }
                //bracketRight keycode = 221
                if (e.keyCode === 221 && currentSectionIndex < indexedSectionList.length - 1) {
                    let nextSectionName = indexedSectionList[currentSectionIndex + 1];
                    onSectionSelect(nextSectionName);
                }
            }
        };
    });

    let findRootSection = (sectionName) => {
        let sectionListItem = sectionList[sectionName];
        if (sectionListItem) {
            while (sectionListItem.parentName) {
                sectionListItem = sectionList[sectionListItem.parentName];
            }
        }
        return formSchema.sections.find(s => s.sectionName === sectionListItem.name);
    };

    let getSubSectionIndex = (targetSectionName, childSectionName) => {
        if (sectionList) {
            let targetSectionIndex = -1;
            childSectionName = childSectionName ? childSectionName : navigation.currentSectionName;
            let childSectionInfo = sectionList[childSectionName];

            //Determine if the childSectionName is a subsection of this section or root, if there is no TargetSectionName
            let childIsChildOfTarget = false;
            if (targetSectionName) {
                childIsChildOfTarget = childSectionInfo.parentName === targetSectionName;
            } else {
                childIsChildOfTarget = !childSectionInfo.parentName;
            }
            if (childIsChildOfTarget) {
                targetSectionIndex = childSectionInfo.index;
            } else if (targetSectionName !== childSectionName && childSectionInfo.parentName) {
                return getSubSectionIndex(targetSectionName, childSectionInfo.parentName);
            }
            if (targetSectionIndex !== -1) {
                return targetSectionIndex;
            }
        }
    };

    let onSectionSelect = (sectionName) => {
        setNavigation({
            currentSectionName: sectionName,
            highlightedFieldKeyArray: [],
            highlightedRecordKey: {}
        });
    };

    let navigateToField = (sectionName, navigationKey, tableName, targetRecordKey) => {
        let navigationStrings = navigationKey.split("//");

        if ((targetRecordKey ?? null) !== null) {
            if (typeof setRecordCurrency === 'function') {
                setRecordCurrency(tableName, targetRecordKey);
            }
            if (sectionName !== navigation.currentSectionName && window.IeVersion().IsIE) {
                setNavigation({
                    currentSectionName: sectionName,
                    highlightedFieldKeyArray: Array.isArray(navigationStrings) && navigationStrings.length > 0 ? navigationStrings : [],
                    highlightedRecordKey: targetRecordKey,
                    forceHighlightedFieldWait: true
                });
            }
            else {
                setNavigation({
                    currentSectionName: sectionName,
                    highlightedFieldKeyArray: Array.isArray(navigationStrings) && navigationStrings.length > 0 ? navigationStrings : [],
                    highlightedRecordKey: targetRecordKey
                });
            }
        }
    };

    useEffect(() => {
        //In IE, something will try to cause the div to scroll back to the top if you use validation to navigate to a field on a different section. The only workaround I could find
        //was to add a forceHighlightedFieldWait property to the navigation state and only set the field to be highlighted half a second after the state-change has completed.
        //This gives ample time for the rerender, which is why it is in use-effect, but if the forceHighlightedFieldWait is reset immedicately the bug will happen, so a timeout was added
        if (navigation.forceHighlightedFieldWait && window.IeVersion().IsIE) {
            setTimeout(() => {
                setNavigation({
                    ...navigation,
                    forceHighlightedFieldWait: false
                });
            }, 500);
        }
    }, [navigation]);

    let clearHighlightedField = () => {
        setNavigation({
            currentSectionName: navigation.currentSectionName,
            highlightedFieldKeyArray: [],
            highlightedRecordKey: {}
        });
    };

    let sectionToUse = findRootSection(navigation.currentSectionName);
    return (
        tabView ?
            <div className={classes.flexWrapper}>
                <div className={classes.sidePanelFlex}>
                    <DynamicFormSidePanel
                        formSchema={formSchema}
                        formData={formData}
                        widgetDataCollection={widgetDataCollection}
                        triggerWidgetCall={triggerWidgetCall}
                        sectionListProps={{
                            sectionList: sectionList,
                            onSectionSelect: onSectionSelect,
                            currentSectionName: navigation.currentSectionName
                        }}
                        onFieldNavigation={navigateToField}
                        mappingElementsStatus={mappingElementsStatus}
                        onUpdateMappingStatus={onUpdateMappingStatus}
                        recordContext={recordContext}
                    />
                </div>
                {
                    (formSecurity.viewOnly && !newEvent && !enableFormEdit) ?
                        <div
                            className={[classes.formBodyFlex, classes.disableForm].join(" ")}
                            key={navigation.currentSectionName}
                        >
                            <DynamicFormSection
                                getSubSectionIndex={getSubSectionIndex}
                                setCurrentSectionName={onSectionSelect}
                                currentSectionName={navigation.currentSectionName}
                                sectionSchema={sectionToUse}
                                sectionList={sectionList}
                                formMetaData={formMetaData}
                                formData={formData}
                                recordContext={recordContext}
                                settings={settings}
                                onBlur={onBlur}
                                onChange={onChange}
                                executeCommandButton={executeCommandButton}
                                onAddRecord={onAddRecord}
                                onDeleteRecords={onDeleteRecords}
                                setRecordCurrency={setRecordCurrency}
                                tabView={tabView}
                                highlightedFieldKeyArray={!navigation?.forceHighlightedFieldWait ? navigation.highlightedFieldKeyArray : []}
                                highlightedRecordKey={!navigation?.forceHighlightedFieldWait ? navigation.highlightedRecordKey : null}
                                clearHighlightedField={clearHighlightedField}
                                triggerScroll={triggerScroll}
                                isLocked={isLocked}
                                mappingElementsStatus={mappingElementsStatus}
                                onUpdateMappingStatus={onUpdateMappingStatus}
                                formLoadProgress={formLoadProgress}
                                viewName={formSchema.header.viewName}
                            />
                        </div>
                        :
                        <div
                            className={classes.formBodyFlex}
                            key={navigation.currentSectionName}
                        >
                            <DynamicFormSection
                                getSubSectionIndex={getSubSectionIndex}
                                setCurrentSectionName={onSectionSelect}
                                currentSectionName={navigation.currentSectionName}
                                sectionSchema={sectionToUse}
                                sectionList={sectionList}
                                formMetaData={formMetaData}
                                formData={formData}
                                recordContext={recordContext}
                                settings={settings}
                                onBlur={onBlur}
                                onChange={onChange}
                                executeCommandButton={executeCommandButton}
                                onAddRecord={onAddRecord}
                                onDeleteRecords={onDeleteRecords}
                                setRecordCurrency={setRecordCurrency}
                                tabView={tabView}
                                highlightedFieldKeyArray={!navigation?.forceHighlightedFieldWait ? navigation.highlightedFieldKeyArray : []}
                                highlightedRecordKey={!navigation?.forceHighlightedFieldWait ? navigation.highlightedRecordKey : null}
                                clearHighlightedField={clearHighlightedField}
                                triggerScroll={triggerScroll}
                                isLocked={isLocked}
                                mappingElementsStatus={mappingElementsStatus}
                                onUpdateMappingStatus={onUpdateMappingStatus}
                                formLoadProgress={formLoadProgress}
                                viewName={formSchema.header.viewName}
                            />
                        </div>
                }
            </div>
            : <div className={classes.flexWrapper}>
                <div className={classes.sidePanelFlex}>
                    <DynamicFormSidePanel
                        formSchema={formSchema}
                        formData={formData}
                        widgetDataCollection={widgetDataCollection}
                        triggerWidgetCall={triggerWidgetCall}
                        onFieldNavigation={navigateToField}
                        mappingElementsStatus={mappingElementsStatus}
                        onUpdateMappingStatus={onUpdateMappingStatus}
                        recordContext={recordContext}
                    />
                </div>
                {
                    (formSecurity.viewOnly && !newEvent && !enableFormEdit) ?
                        <div className={[classes.formBodyFlex, classes.disableForm].join(" ")}>
                            {formSchema.sections.sort(sortSectionsBySequence).map(sectionSchema => (
                                <DynamicFormSection
                                    sectionSchema={sectionSchema}
                                    formMetaData={formMetaData}
                                    formData={formData}
                                    executeCommandButton={executeCommandButton}
                                    recordContext={recordContext}
                                    settings={settings}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    onAddRecord={onAddRecord}
                                    onDeleteRecords={onDeleteRecords}
                                    setRecordCurrency={setRecordCurrency}
                                    tabView={tabView}
                                    highlightedFieldKeyArray={navigation.highlightedFieldKeyArray}
                                    highlightedRecordKey={navigation.highlightedRecordKey}
                                    clearHighlightedField={clearHighlightedField}
                                    triggerScroll={triggerScroll}
                                    isLocked={isLocked}
                                    mappingElementsStatus={mappingElementsStatus}
                                    onUpdateMappingStatus={onUpdateMappingStatus}
                                    formLoadProgress={formLoadProgress}
                                    viewName={formSchema.header.viewName}
                                />
                            ))}
                        </div>
                        :
                        <div className={classes.formBodyFlex}>
                            {formSchema.sections.sort(sortSectionsBySequence).map(sectionSchema => (
                                <DynamicFormSection
                                    sectionSchema={sectionSchema}
                                    formMetaData={formMetaData}
                                    formData={formData}
                                    executeCommandButton={executeCommandButton}
                                    recordContext={recordContext}
                                    settings={settings}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    onAddRecord={onAddRecord}
                                    onDeleteRecords={onDeleteRecords}
                                    setRecordCurrency={setRecordCurrency}
                                    tabView={tabView}
                                    highlightedFieldKeyArray={navigation.highlightedFieldKeyArray}
                                    highlightedRecordKey={navigation.highlightedRecordKey}
                                    clearHighlightedField={clearHighlightedField}
                                    triggerScroll={triggerScroll}
                                    isLocked={isLocked}
                                    mappingElementsStatus={mappingElementsStatus}
                                    onUpdateMappingStatus={onUpdateMappingStatus}
                                    formLoadProgress={formLoadProgress}
                                    viewName={formSchema.header.viewName}
                                />
                            ))}
                        </div>
                }
            </div>
    );
}

export default (connect((state) => ({ patientInfo: state.demographics }))(withStyles(styles)(DynamicForm)));