import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import React from 'react';
import { Responsive, WidthProvider } from "react-grid-layout";
import FormEditorSectionPropertyEditor from './FormEditorSectionPropertyEditor';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    paper: {
        marginTop: `${theme.spacing(3)}px`,
        width: 'initial'
    },
    listHeader: {
        marginLeft: '15px'
    }
}));

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function FormEditorSectionList(props) {
    const classes = useStyles();

    let layouts = {
        xs: props.sections.map((section, index) => {

            return ({
                i: index.toString(),
                x: 0,
                y: section.sequence,
                w: 1,
                h: 2,
                isResizable: false
            });
        })
    };

    return (
        <Paper className={classes.paper}>
            <Typography align={'left'} className={classes.listHeader} variant={'subtitle1'}>
                {'SECTIONS'}
            </Typography>
            <ResponsiveReactGridLayout
                breakpoints={{ xs: 0 }}
                cols={{ xs: 1 }}
                layouts={layouts}
                rowHeight={20}
                margin={[10, 0]}
                containerPadding={[0, 0]}
                onLayoutChange={props.updateSectionLayout}
                compactType={"vertical"}
            >
                {props.sections.map((section, index) => (
                    //Isn't using an index as a key not best practice? Especially if the index is likely to change
                    <div key={index}>
                        <Divider />
                        <FormEditorSectionPropertyEditor
                            section={section}
                            handleUpdateSectionHide={props.updateSectionHide}
                            handleUpdateSectionDisplayName={props.updateSectionDisplayName}
                        />
                        <ListItem className={index === props.currentSectionIndex ? classes.selected : ''} button onClick={() => props.onClick(index)}>
                            <Typography variant={'subtitle2'}>
                                {section.sectionDisplayName.toUpperCase()}
                            </Typography>
                        </ListItem>
                    </div>
                ))}
            </ResponsiveReactGridLayout>
        </Paper>
    );
}                    