import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Link from '@material-ui/icons/Link';
import { MappedMissingIcon, ConflictingConfirmedIcon, NonConflictingConfirmedIcon, ConflictingUnconfirmedIcon, NonConflictingUnconfirmedIcon, MappingErrorIcon } from './EmrIcons/Icons';
import EmrValueList from './EmrValueList';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
    iconButton: {
        padding: "0px !important",
        height: '18px !important',
        borderRadius: "4px",
        minWidth: "24px"
    },
    map: {
        height: '16px',
        width: '22px',
        float: "left",
        color: theme.palette.text.secondary
    },
    container: {
        background: theme.palette.background.selected,
        height: "18px",
        position: 'relative',
        '& .icon': {
            height: '16px',
            width: '22px'
        },
        "& .agreeingButton": {
            backgroundColor: theme.palette.lxGreen.light,
            padding: "0",
            "& .icon": {
                color: theme.palette.text.secondary
            }
        },
        "& .agreeingButton:hover": {
            backgroundColor: theme.palette.lxGreen.dark,
            padding: "0",
            "& .icon": {
                color: theme.palette.text.contrast
            }
        },
        "& .mismatchButton": {
            backgroundColor: theme.palette.warning.light,
            padding: "0",
            "& .icon": {
                color: theme.palette.text.secondary
            }
        },
        "& .mismatchButton:hover": {
            backgroundColor: theme.palette.warning.dark,
            padding: "0",
            "& .icon": {
                color: theme.palette.text.contrast
            }
        }
    },
    popper: {
        zIndex: "500000"
    }
});

var emrMappingStatus = {
    MAPPED_MISSING: "Mapped-Missing",
    MAPPED: "Mapped",
    MAPPING_ERROR: "Mapping-Error"
};

class EmrValueSelector extends React.Component {
    constructor(props) {
        super(props);
        this.selectorOpenFromPanel = true;
        this.isEmrValueSelected = false;
        this.state = {
            valueSelectorOpen: false,
            valueSelectorOpenFromPanel: true,
            anchorEl: null
        };
    }

    isEpicDataConflicting = () => {
        let valueOptions = this.newValueOptions();
        let conflicting = false;
        if (Array.isArray(valueOptions)) {
            if (valueOptions.length > 0) {
                let initialValue = valueOptions[0].value;
                valueOptions.forEach(option => {
                    if (initialValue !== option.value) {
                        conflicting = true;
                    }
                });
            }
            else {
                conflicting = false;
            }
        }
        return conflicting;
    }

    isApolloDataConflicting = (valueFromEpicWindow) => {
        const { value } = this.props;
        let tempValue;
        if (valueFromEpicWindow !== null) {
            tempValue = valueFromEpicWindow;
        }
        else {
            tempValue = value
        }
        let valueOptions = this.newValueOptions();
        let conflicting = false;
        if (Array.isArray(valueOptions)) {
            if (!this.isValueNothing() && valueOptions.length > 0) {
                valueOptions.forEach(option => {
                    let apolloValue;
                    if (typeof option.value === 'number') {
                        apolloValue = Number(tempValue);
                    }
                    else {
                        apolloValue = value;
                    }
                    if (apolloValue !== option.value) {
                        conflicting = true;
                    }
                });
            }
            else {
                conflicting = false;
            }
        }
        return conflicting;
    }

    setAnchorRef = (anchorElement) => {
        this.setState({
            anchorEl: anchorElement
        });
    }

    openValueSelector = () => {
        this.setState({
            valueSelectorOpen: true
        });
        this.selectorOpenFromPanel = true;
        window.addEventListener('scroll', this.closeValueSelector);
    }

    closeValueSelector = () => {
        this.setState({
            valueSelectorOpen: false
        });
        this.selectorOpenFromPanel = true;
        window.removeEventListener('scroll', this.closeValueSelector);
    }

    closeValueSelectorFromPanel = () => {
        //Here setting the dummy state to execute the redner again. This was needed when the EMR value selector popup is open from the FHIR panel.
        this.selectorOpenFromPanel = false;
        this.setState({
            valueSelectorOpenFromPanel: false,
            valueSelectorOpen: false
        });
        window.removeEventListener('scroll', this.closeValueSelectorFromPanel);
        this.selectorOpenFromPanel = true;
    }

    handleValueSelected = (value) => {
        const { onValueSelect } = this.props;

        this.closeValueSelector();
        this.closeValueSelectorFromPanel();
        onValueSelect(value);

        let isConflicting = this.isEpicDataConflicting() || this.isApolloDataConflicting(value);
        this.updateMappingStatus(isConflicting, 'Confirmed', 'Post-Initialization');
        this.isEmrValueSelected = true;
    }

    updateMappingStatus = (isConflicting, status, remark) => {
        const { metaDataKey, onUpdateMappingStatus } = this.props;
        onUpdateMappingStatus(metaDataKey, isConflicting, status, remark)
    }

    isValueNothing = () => {
        const { value } = this.props;
        if ((value === undefined || value === null || value === '')) {
            return true;
        }
        return false;
    }

    isDefaultValueNothing = () => {
        let fieldElement = this.getElementFromMappingElements();
        if (fieldElement !== undefined && (fieldElement.remark === "Initialization" || fieldElement.remark === "Post-Initialization")) {
            if ((fieldElement.elementDefaultValue === undefined || fieldElement.elementDefaultValue === null || fieldElement.elementDefaultValue === '')) {
                return true;
            }
        }
        return false;
    }

    isNoEmrOptionsForEveryElement = (newMappingElementsStatus) => {
        let result = newMappingElementsStatus.every(element => {
            if (element.emrOptions.length === 0) {
                return true;
            }
        });
        return result;
    };

    renderMap = (hasMappings, classes) => {
        const { mappingElementsStatus } = this.props;
        if (hasMappings) {
            let newMappingElementsStatus = Array.isArray(mappingElementsStatus) ? [...mappingElementsStatus] : [];
            let isNoEmrOptionsForAllelements = this.isNoEmrOptionsForEveryElement(newMappingElementsStatus);
            let fieldElement = this.getElementFromMappingElements();
            if (fieldElement !== undefined && fieldElement.mappedStatus === "Mapping-Error" && !isNoEmrOptionsForAllelements) {
                return (
                    <Tooltip title={fieldElement.mappingErrorDesc}>
                        <Button className={classes.iconButton} tabIndex="-1">
                            <MappingErrorIcon />
                        </Button>
                    </Tooltip>
                );
            } else {
                return (
                    <Button className={classes.iconButton} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                        <Link className={classes.map} />
                    </Button>
                );
            }
        }
    };

    getApolloValueOption = (elementDefaultValue) => {
        let valueOption = {
            value: elementDefaultValue,
            mapping: {
                emrSource: {
                    sourceType: 'Database',
                    source: 'Apollo Database',
                    sourceConfig: ''
                },
                expression: '',
                mappingElements: [],
                missingExpression: '',
                name: ''
            }
        };
        return valueOption;
    }

    renderIcon = (classes) => {
        const { value, emrInfo } = this.props;
        if (emrInfo !== undefined) {
            var strValue = value === null && emrInfo.emrElementDefaultValue === '' ? '' : value === null && emrInfo.emrElementDefaultValue === null ? null : String(value);
            if (emrInfo.emrRemark === "Initialization") {
                if (strValue === emrInfo.emrElementDefaultValue) {
                    switch (emrInfo.emrMappingStatus) {
                        case emrMappingStatus.MAPPED_MISSING:
                            return (
                                <Button className={classes.iconButton} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                                    <Link className={classes.map} />
                                </Button>
                            );
                        case emrMappingStatus.MAPPED: {
                            let isConflicting = this.isEpicDataConflicting() || this.isApolloDataConflicting(value);
                            if (this.isEmrValueSelected === true) {
                                this.isEmrValueSelected = false;
                                if (isConflicting) {
                                    return (
                                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                                            <ConflictingConfirmedIcon />
                                        </Button>
                                    );
                                } else {
                                    return (
                                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                                            <NonConflictingConfirmedIcon />
                                        </Button>
                                    );
                                }
                            } else {
                                if (emrInfo.emrConflictStatus === "Nonconflicting data" && emrInfo.emrConfirmationStatus === "Confirmed") {
                                    return (
                                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                                            <NonConflictingConfirmedIcon />
                                        </Button>
                                    );
                                } else if (emrInfo.emrConflictStatus === "Conflicting data" && emrInfo.emrConfirmationStatus === "Confirmed") {
                                    return (
                                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                                            <ConflictingConfirmedIcon />
                                        </Button>
                                    );
                                } else if (emrInfo.emrConflictStatus === "Nonconflicting data" && emrInfo.emrConfirmationStatus === "Unconfirmed") {
                                    return (
                                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                                            <NonConflictingUnconfirmedIcon />
                                        </Button>
                                    );
                                } else if (emrInfo.emrConflictStatus === "Conflicting data" && emrInfo.emrConfirmationStatus === "Unconfirmed") {
                                    return (
                                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                                            <ConflictingUnconfirmedIcon />
                                        </Button>
                                    );
                                }
                            }
                        }
                        case emrMappingStatus.MAPPING_ERROR:
                            return (
                                <Tooltip title={emrInfo.emrMappingErrorDesc}>
                                    <Button className={classes.iconButton} tabIndex="-1">
                                        <MappingErrorIcon />
                                    </Button>
                                </Tooltip>
                            );
                        default:
                            return (
                                <Button className={classes.iconButton} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                                    <Link className={classes.map} />
                                </Button>
                            );
                    }
                }
                else {
                    switch (emrInfo.emrMappingStatus) {
                        case emrMappingStatus.MAPPED_MISSING:
                            return (
                                <Button className={classes.iconButton} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                                    <Link className={classes.map} />
                                </Button>
                            );
                        case emrMappingStatus.MAPPED:
                            return this.changeEpicIndicatorsOnUserClick(classes, emrInfo);
                        case emrMappingStatus.MAPPING_ERROR:
                            return (
                                <Tooltip title={emrInfo.emrMappingErrorDesc}>
                                    <Button className={classes.iconButton} tabIndex="-1">
                                        <MappingErrorIcon />
                                    </Button>
                                </Tooltip>
                            );
                        default:
                            return (
                                <Button className={classes.iconButton} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                                    <Link className={classes.map} />
                                </Button>
                            );
                    }
                }
            }
            else {
                switch (emrInfo.emrMappingStatus) {
                    case emrMappingStatus.MAPPED_MISSING:
                        return (
                            <Button className={classes.iconButton} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                                <Link className={classes.map} />
                            </Button>
                        );
                    case emrMappingStatus.MAPPED:
                        return this.changeEpicIndicatorsOnUserClick(classes, emrInfo);
                    case emrMappingStatus.MAPPING_ERROR:
                        return (
                            <Tooltip title={emrInfo.emrMappingErrorDesc}>
                                <Button className={classes.iconButton} tabIndex="-1">
                                    <MappingErrorIcon />
                                </Button>
                            </Tooltip>
                        );
                    default:
                        return (
                            <Button className={classes.iconButton} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                                <Link className={classes.map} />
                            </Button>
                        );
                }
            }
        }
    };

    changeEpicIndicatorsOnUserClick = (classes, emrInfo) => {
        let isConflicting = this.isEpicDataConflicting() || this.isApolloDataConflicting(null);
        if (this.isDefaultValueNothing()) {
            if (this.isValueNothing()) {
                this.updateMappingStatus(isConflicting, 'Unconfirmed', 'Post-Initialization');
                if (isConflicting) {
                    return (
                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                            <ConflictingUnconfirmedIcon />
                        </Button>
                    );
                }
                else {
                    return (
                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                            <NonConflictingUnconfirmedIcon />
                        </Button>
                    );
                }
            } else {
                this.updateMappingStatus(isConflicting, 'Confirmed', 'Post-Initialization');
                if (isConflicting) {
                    return (
                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                            <ConflictingConfirmedIcon />
                        </Button>
                    );
                }
                else {
                    return (
                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                            <NonConflictingConfirmedIcon />
                        </Button>
                    );
                }
            }
        } else {
            if (this.isValueNothing()) {
                this.updateMappingStatus(isConflicting, 'Unconfirmed', 'Post-Initialization');
                if (isConflicting) {
                    return (
                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                            <ConflictingUnconfirmedIcon />
                        </Button>
                    );
                }
                else {
                    return (
                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                            <NonConflictingUnconfirmedIcon />
                        </Button>
                    );
                }
            } else {
                this.updateMappingStatus(isConflicting, 'Confirmed', 'Post-Initialization');
                if (isConflicting) {
                    return (
                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                            <ConflictingConfirmedIcon />
                        </Button>
                    );
                }
                else {
                    return (
                        <Button className={classes.iconButton} onClick={this.openValueSelector} ref={this.setAnchorRef} tabIndex="-1" disabled={emrInfo.emrDisabled}>
                            <NonConflictingConfirmedIcon />
                        </Button>
                    );
                }
            }
        }
    }

    newValueOptions = () => {
        const { emrInfo } = this.props;

        let valOptions = [];
        if (emrInfo !== undefined && emrInfo.emrOptions !== null && emrInfo.emrOptions !== undefined) {
            if (emrInfo.emrOptions.length > 0) {
                emrInfo.emrOptions.forEach(option => {
                    let valueOption = {
                        value: option.value,
                        mapping: {
                            emrSource: {
                                sourceType: option.sourceType,
                                source: option.source,
                                sourceConfig: ''
                            },
                            expression: '',
                            mappingElements: option.emrOptionElements,
                            missingExpression: '',
                            name: option.mapping
                        }
                    };
                    valOptions.push(valueOption);
                });

                if (emrInfo.emrApolloOption !== '') {
                    let apolloOption = this.getApolloValueOption(emrInfo.emrElementDefaultValue);
                    valOptions.push(apolloOption);
                }
            } else {
                try {
                    valOptions = [...emrInfo.emrOptions];
                    if (emrInfo.emrApolloOption !== '') {
                        let apolloOption = this.getApolloValueOption(emrInfo.emrElementDefaultValue);
                        valOptions.push(apolloOption);
                    }
                } catch (error) {
                    console.log('error', error);
                }
            }
        }
        return valOptions;
    }

    getElementFromMappingElements = () => {
        const { mappingElementsStatus, metaDataKey } = this.props;
        let newMappingElementsStatus = Array.isArray(mappingElementsStatus) ? [...mappingElementsStatus] : [];
        let fieldElement = newMappingElementsStatus.find(elementStatus => elementStatus.element === metaDataKey);
        return fieldElement;
    }

    render() {
        const { className, classes, hasMappings, valueOptions } = this.props;
        const { valueSelectorOpen, anchorEl } = this.state;
        let valOptions = this.newValueOptions();

        return (
            <React.Fragment>
                <ButtonGroup variant="outlined" className={`${classes.container} ${className}`}>
                    {hasMappings ?
                        this.renderIcon(classes) :
                        null
                    }
                </ButtonGroup>
                <Popper
                    open={valueSelectorOpen}
                    anchorEl={anchorEl}
                    placement="top"
                    className={`${classes.popper} nonDraggableSection`}
                >
                    <ClickAwayListener onClickAway={this.closeValueSelectorFromPanel}>
                        <Paper>
                            <EmrValueList options={valOptions} onValueSelect={this.handleValueSelected} />
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(EmrValueSelector);