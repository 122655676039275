import { Collapse, ListItemAvatar, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';

import CollapseWrapper from './CollapseWrapper';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    paper: {
        width: 'initial'
    },
    listHeader: {
        marginLeft: '15px'
    },
    headerDiv: {
        height: '28px',
        position: 'relative',
        cursor: 'move'
    },
    minimizeIconButton: {
        width: '24px',
        height: '24px',
        position: 'absolute',
        right: '5px',
        top: '2px'
    }
});

function FormSectionList(props) {
    const { sections, currentSectionName, onClick, classes } = props;
    const [sectionHierarchy, setSectionHierarchy] = React.useState([]);
    const [openCollection, setOpenCollection] = React.useState({});

    useEffect(() => {
        let createHierarchy = () => {
            if (sections) {
                let tieredSections = [];

                Object.getOwnPropertyNames(sections).forEach((sectionName) => {
                    let section = sections[sectionName];
                    if (!section.parentName) {
                        tieredSections.push(section);
                    }
                });
                tieredSections.forEach((section) => {
                    section.children = getSectionChildren(section.name);
                });

                let sortedTieredSections = tieredSections.sort((a, b) => a.sequence - b.sequence);
                return sortedTieredSections;
            }
        };

        let getSectionChildren = (sectionName) => {
            let children = [];
            Object.getOwnPropertyNames(sections).forEach((potentialChildSectionName) => {
                let potentialChildSection = sections[potentialChildSectionName];
                if (potentialChildSection.parentName === sectionName) {
                    children.push(potentialChildSection);
                }
            });
            children.forEach((childSection) => {
                childSection.children = getSectionChildren(childSection.name);
            });
            return children;
        };

        setSectionHierarchy(createHierarchy());
    }, [sections]);

    let toggleOpen = (sectionName) => (e) => {
        e.stopPropagation();
        let newOpenCollection = { ...openCollection };
        let targetSectionOpen = openCollection[sectionName] ?? false;
        newOpenCollection[sectionName] = !targetSectionOpen;
        setOpenCollection(newOpenCollection);
    };

    let renderSectionListItem = (section, subsectionDepth = 0) => {
        let sectionName = section.name;
        let paddingAmount = `${subsectionDepth * 20}px`;

        return (
            <React.Fragment key={sectionName}>
                <Divider />
                <ListItem className={sectionName === currentSectionName ? classes.selected : ''} style={{ paddingLeft: paddingAmount }} button onClick={() => onClick(sectionName)}>
                    <ListItemAvatar onClick={toggleOpen(sectionName)} style={{ minWidth: '35px' }}>
                        {section.children.length > 0 ?
                            openCollection[sectionName] || section.children.some(childSection => childSection.name === currentSectionName) ?
                                <KeyboardArrowDown />
                                : <KeyboardArrowRight />
                            : <div />}
                    </ListItemAvatar>
                    <Typography variant={'subtitle2'}>
                        {sections[sectionName].displayName?.toUpperCase()}
                    </Typography>
                </ListItem>
                <Collapse in={openCollection[sectionName] || section.children.some(childSection => childSection.name === currentSectionName)}>
                    {section.children.map((childSection) => renderSectionListItem(childSection, subsectionDepth + 1))}
                </Collapse>
            </React.Fragment>
        );
    };

    return (
        <List component="nav">
            {sectionHierarchy?.map((section) => {
                return (
                    (!section.hide) ?
                        renderSectionListItem(section) : null
                );
            })}
        </List>
    );

}

export default withStyles(styles)(CollapseWrapper(FormSectionList));