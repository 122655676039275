import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';
import { withRouter } from 'react-router-dom';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import modalStyle from '.../assets/jss/material-dashboard-pro-react/modalStyle';
import LxDatePicker from '.../components/formComponents/LxDatePicker';
import LxTextField from '.../components/formComponents/LxTextField';
import { demographicsApi } from '.../utils/apiHelper';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const styles = theme => ({
    ...modalStyle(theme),
    dialog: {
        width: '100vh',
        overflow: 'visible',
        '@media (min-width: 960px)': {
            width: '60vw',
            overflow: 'visible'
        }
    },
    confirmationDialog: {
        width: '100vh',
        overflow: 'visible',
        '@media (min-width: 960px)': {
            width: '60vw',
            height: '30vh',
            minHeight: '225px'
        }
    },
    confirmationDialogContent: {
        paddingTop: '0px',
        maxHeight: '125px'
    },
    confirmationValues: {
        margin: `${theme.spacing(3)}px auto`,
        maxWidth: '300px'
    },
    dialogContent: {
        height: '65vh',
        overflow: 'visible',
        overflowY: 'auto',
        paddingTop: '0px'
    },
    closeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    },
    hiddenButton: {
        display: 'none'
    },
    confirmationMessage: {
        margin: `${theme.spacing(1)}px`
    },
    formMessage: {
        margin: `${theme.spacing(4)}px`
    },
    formItem: {
        width: '100%',
        marginBottom: `${theme.spacing(4)}px`
    },
    requestStatus: {
        height: 'inherit',
        display: 'table'
    },
    requestIcon: {
        display: 'table-cell',
        verticalAlign: 'middle',
        fontSize: '60px'
    },
    customMsg: {
        display: 'flex',
        flexDirection: 'row'
    }
});

const StyledWarningIcon = withStyles((theme) => ({
    root: {
        color: theme.palette.warning.dark,
        height: 'inherit'
    }
}))(ReportProblemOutlinedIcon);

class CreateNewPatient extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            internalMrn: '',
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            currentPatient: props.currentPatient,
            ...props.currentPatient,
            confirmDialogOpen: false,
            isLoading: false,
            isDone: false,
            isError: false,
            patientExistsDialogOpen: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (JSON.stringify(nextProps.currentPatient) !== JSON.stringify(prevState.currentPatient)) {
            return ({
                currentPatient: nextProps.currentPatient,
                ...nextProps.currentPatient
            });
        }

        return null;
    }

    handlePatientCreation = () => {
        const { successfulCallback, failureCallback, mrn } = this.props;
        const { internalMrn, firstName, lastName, dateOfBirth } = this.state;

        let currMrn = typeof mrn === 'string' ? mrn : internalMrn;

        this.setState({
            isLoading: true,
            isDone: false,
            isError: false
        });

        demographicsApi.createPatient(currMrn, firstName, lastName, new Date(dateOfBirth).toISOString())
            .then(ssPatientId => {
                this.setState({
                    isLoading: true,
                    isDone: true,
                    isError: false
                });

                setTimeout(() => {
                    successfulCallback({
                        mrn: currMrn,
                        uniqueSourceId: ssPatientId,
                        firstName,
                        lastName,
                        dateOfBirth
                    });
                    this.resetComponent();
                }, 500);

            })
            .catch(error => {
                var theBadRequestSubstringExp = "400 (Bad Request)";
                if (error.response.data !== null && error.response.data.includes(theBadRequestSubstringExp) !== null) {
                    this.setState({ isLoading: false })
                    this.setState({ confirmDialogOpen: false })
                    this.setState({ patientExistsDialogOpen: true })
                } else {
                    this.setState({
                        isLoading: true,
                        isError: true,
                        isDone: false
                    });

                    setTimeout(() => {
                        failureCallback(error);
                        this.resetComponent();
                    }, 500);
                }
            });
    }

    resetComponent = () => {
        const { currentPatient } = this.props;
        this.setState({
            isLoading: false,
            isError: false,
            isDone: false,
            confirmDialogOpen: false,
            patientExistsDialogOpen: false,
            internalMrn: '',
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            currentPatient: currentPatient,
            ...currentPatient
        });
    }

    handleConfirmationToggle = () => this.setState({ confirmDialogOpen: !this.state.confirmDialogOpen })

    checkValidBirthDate = () => {
        const { dateOfBirth } = this.state;
        let dateValue = new Date(dateOfBirth);
        let dateMs = dateValue.getTime();

        return dateOfBirth !== '' && !isNaN(dateMs) && dateMs <= Date.now();
    }

    handlePatientExistsDialogClose() {
        this.setState({
            patientExistsDialogOpen: false
        });
    }

    render() {
        const { classes, open, mrn, handleClose } = this.props;
        const { internalMrn, firstName, lastName, dateOfBirth, confirmDialogOpen, isLoading, isDone, isError, patientExistsDialogOpen } = this.state;

        let currMrn = typeof mrn === 'string' ? mrn : internalMrn;

        return (
            <Dialog classes={{ paper: classes.dialog }} open={open} maxWidth={false}>

                <DialogTitle disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>{'Create New Patient'}</h4>
                    <Close
                        className={classes.closeIcon}
                        onClick={handleClose}
                    />
                </DialogTitle>

                <DialogContent className={`${classes.modalBody} ${classes.dialogContent}`}>
                    <h4 className={classes.formMessage}>
                        {typeof mrn === 'string' ?
                            `Patient with MRN: ${mrn} does not exist in the database. Enter the information below to add them to the database`
                            :
                            ''
                        }
                    </h4>

                    <form onSubmit={this.handleConfirmationToggle}>

                        <div className={classes.formItem}>
                            <LxTextField
                                title='MRN'
                                error={currMrn === ''}
                                helperText={currMrn === '' ? 'MRN is missing' : ''}
                                className={classes.formItem}
                                disabled={mrn === currMrn}
                                value={currMrn}
                                onChange={(value) => this.setState({ internalMrn: value })}
                            />
                        </div>

                        <div className={classes.formItem}>
                            <LxTextField
                                title='First Name'
                                error={firstName === ''}
                                helperText={firstName === '' ? 'First Name is missing' : ''}
                                className={classes.formItem}
                                value={firstName}
                                onChange={(value) => this.setState({ firstName: value })}
                            />
                        </div>

                        <div className={classes.formItem}>
                            <LxTextField
                                title='Last Name'
                                error={lastName === ''}
                                helperText={lastName === '' ? 'Last Name is missing' : ''}
                                className={classes.formItem}
                                value={lastName}
                                onChange={(value) => this.setState({ lastName: value })}
                            />
                        </div>

                        <div className={classes.formItem}>
                            <LxDatePicker
                                title='Date of Birth'
                                value={dateOfBirth === '' ? null : dateOfBirth}
                                onChange={(value) => this.setState({ dateOfBirth: value === null ? '' : value })}
                                error={dateOfBirth === ''}
                                helperText={dateOfBirth === '' ? 'Date of birth is missing' : ''}
                                disableFuture
                            />
                        </div>

                        <Button variant='contained' color='primary' type='submit' className={classes.hiddenButton}>
                            {''}
                        </Button>
                    </form>
                </DialogContent>

                <DialogActions>
                    <GridContainer spacing={4} justify='flex-end' alignItems='baseline'>
                        <GridItem>
                            <Button variant='contained' color='primary' disabled={currMrn === '' || firstName === '' || lastName === '' || !this.checkValidBirthDate()} onClick={this.handleConfirmationToggle}>
                                {'SUBMIT'}
                            </Button>
                        </GridItem>
                    </GridContainer>
                </DialogActions>

                <Dialog classes={{ paper: classes.confirmationDialog }} open={confirmDialogOpen}>

                    {isLoading ?
                        <div className={classes.requestStatus}>
                            <div className={classes.requestIcon}>
                                {isDone ?
                                    <DoneIcon color='primary' fontSize='inherit' />
                                    :
                                    isError ?
                                        <ErrorIcon color='error' fontSize='inherit' />
                                        :
                                        <CircularProgress size={60} thickness={7} />
                                }
                            </div>
                        </div>
                        :
                        <React.Fragment>
                            <DialogTitle disableTypography className={classes.modalHeader}>
                                <h4 className={classes.modalTitle}>{'Confirm Patient Creation'}</h4>
                            </DialogTitle>

                            <DialogContent className={`${classes.modalBody} ${classes.confirmationDialogContent}`}>

                                <h4 className={classes.confirmationMessage}>{`Confirm the information below is correct before submitting`}</h4>

                                <GridContainer className={classes.confirmationValues} alignItems='center'>
                                    {[
                                        { title: 'MRN', value: currMrn },
                                        { title: 'First Name', value: firstName },
                                        { title: 'Last Name', value: lastName },
                                        { title: 'Date of Birth', value: new Date(dateOfBirth).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }) }
                                    ].map((item) => (
                                        <React.Fragment key={item.title}>
                                            <GridItem xs={6}>
                                                <Typography align='left' color='inherit' variant='body2'>
                                                    {item.title}
                                                </Typography>
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <Typography align='right' color='inherit' variant='body2'>
                                                    {item.value}
                                                </Typography>
                                            </GridItem>
                                        </React.Fragment>
                                    ))}
                                </GridContainer>

                            </DialogContent>

                            <DialogActions>
                                <GridContainer spacing={4} justify='space-between' alignItems='baseline'>
                                    <GridItem>
                                        <Button variant='contained' color='primary' onClick={this.handleConfirmationToggle}>
                                            {'CANCEL'}
                                        </Button>
                                    </GridItem>
                                    <GridItem>
                                        <Button variant='contained' color='primary' onClick={this.handlePatientCreation}>
                                            {'SUBMIT'}
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </DialogActions>
                        </React.Fragment>
                    }

                </Dialog>

                <Dialog classes={{ paper: classes.confirmationDialog }} open={patientExistsDialogOpen}>
                    {
                        <React.Fragment>
                            <DialogTitle>
                                <b><h4 className={classes.modalTitle}>{'Patient already exists'}</h4></b>
                                <Close
                                    className={classes.closeIcon}
                                    onClick={() => this.handlePatientExistsDialogClose()}
                                />
                            </DialogTitle>
                            <DialogContent className={`${classes.modalBody} ${classes.confirmationDialogContent}`}>
                                <br />
                                <br />
                                <div className={classes.customMsg}>
                                    <StyledWarningIcon />&nbsp;&nbsp;
                                    <h4 className={classes.confirmationMessage}>{`The patient with MRN: ` + currMrn + ` already exists. Please enter different mrn.`}</h4>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <GridContainer spacing={4} justify='flex-end' alignItems='baseline'>
                                    <GridItem>
                                        <Button variant='contained' color='primary' onClick={() => this.handlePatientExistsDialogClose()}>
                                            {'Close'}
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </DialogActions>
                        </React.Fragment>
                    }
                </Dialog>
            </Dialog>
        );
    }
}

export default withRouter(withStyles(styles)(CreateNewPatient));