import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import logo from ".../assets/img/lumedx.ico";
import { sessionStorageHelper } from '.../utils/sessionStorageHelper';
import userManager from '.../utils/userManager';

const styles = theme => ({
    loginPage: {
        width: '360px',
        padding: '8% 0 0',
        margin: 'auto'
    },
    loginButton: {
        fontFamily: 'Roboto, sans-serif',
        textTransform: 'uppercase',
        outline: '0',
        width: '100%',
        border: '0',
        fontSize: '14px',
        '-webkit-transition': 'all 0.3 ease',
        transition: 'all 0.3 ease',
        cursor: 'pointer'
    },
    form: {
        position: 'relative',
        zIndex: '1',
        background: '#FFFFFF',
        maxWidth: '360px',
        margin: '0 auto 100px',
        padding: '45px',
        textAlign: 'center',
        boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)',
        '& input': {
            fontFamily: 'Roboto, sans-serif',
            outline: '0',
            background: '#f2f2f2',
            width: '100%',
            border: '0',
            margin: '0 0 15px',
            padding: '15px',
            boxSizing: 'border-box',
            fontSize: '14px'
        }
    },
    message: {
        margin: '15px 0 0',
        color: '#b3b3b3',
        fontSize: '12px',
        '& a': {
            color: '#4682B4',
            textDecoration: 'none'
        }
    }
});

class Login extends Component {
    componentDidMount() {
        if (this.props.location && !this.props.location.pathname !== '/#/silent_renew#' && sessionStorageHelper.getIsSigningOut() === false) {
            userManager.getUser().then(response => {
                if (!response || response.expired) {
                    this.loginRedirect();
                }
            });
        }
    }

    loginRedirect = () => {
        localStorage.setItem('redirectUrl', this.props.location.pathname);
        userManager.signinRedirect();
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.loginPage} >
                <div className={classes.form} >
                    <img src={logo} alt="Lumedx Logo" />
                    <Button
                        className={classes.loginButton}
                        onClick={this.loginRedirect}
                        color='primary'
                        variant='contained'
                    >
                        Login
                    </Button>
                    <p className={classes.message}>© <a href="www.lumedx.com">Lumedx</a> 2024 </p>
                </div>
                <Snackbar
                    open={sessionStorageHelper.getIsSigningOut() === false}
                    message="Redirecting... Click login if you are not redirected"
                    onClose={this.loginRedirect}
                    autoHideDuration={5000}
                />
            </div>
        );
    }
}

export default withStyles(styles)(withRouter(Login));
